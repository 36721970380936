import React from "react";
import { SoundwaveIcon , MusicNoteListIcon } from "./BootstrapIcons";
import '../styles/components/DemoListComponent.css';

const DemoListComponent = ({ demos, setDemo , demoSelected }) => {
    return ( 
        <div className="DemosList">
            {demos.map((demo, index) => <DemoElement key={index} demo={demo} setDemo={setDemo} demoSelected={demoSelected} />)}
        </div>
    );
};

const DemoElement = ({ demo, setDemo , demoSelected }) => {

    const handleClick = () => {
        console.log("handleClick: ", demo.id);
        demoSelected = demo;
        setDemo(demo);
    };

    return (
        <div className="DemoElement" key={demo.id} onClick={handleClick} style={(demoSelected && demoSelected.id===demo.id)?{'background-color':'rgba(255,255,255,0.5'}:{}}>
            <span className="DemoElement__nombre">
            {(demoSelected && demoSelected.id===demo.id)?
                <SoundwaveIcon size="24px" style={{'marginRight':'10px'}} />
            :
                <MusicNoteListIcon size="24px" style={{'marginRight':'10px'}} />
            }
                {demo.perfil}
            </span>
            <span className="DemoElement__configuracion">
                <span>{demo.tipo}</span>
                <span>{demo.edad}</span>
                <span>{demo.emocion}</span>
            </span>
        </div>
    );
};

export default DemoListComponent;
import React from "react";
import "../styles/components/PaginateComponent.css";

const PaginateComponent = ({ page_number, num_pages, total_items, setPage }) => {
    let page_numbers = [];
    for (let i = 1; i <= num_pages; i++) {
        page_numbers.push(i);
    }
    return (
        <>
            <ul className="PaginateComponent">
                {page_numbers.map((pag) => (
                    <li key={pag} onClick={() => setPage(pag)}>{pag}</li>
                ))}
            </ul>
            <p>Página {page_number} de {num_pages} - {total_items} items</p>
        </>
    );
};

export default PaginateComponent;

import React from "react";
import "../styles/views/LoginView.css";
//import { backendRoot } from "../contexts/BackendProps";
import { serviceFetch } from "../utils/serviceFetch";
import { landing } from "../contexts/BackendProps";
import { EyeIcon, KeyFillIcon, PhoneIcon } from "../components/BootstrapIcons";

const LoginView = ({ handleOnSubmit, handleSignup = e => null, setMessage = e=>null }) => {

    const inputUser = React.createRef();
    const [tycShow, setTycShow] = React.useState(false);

    const handleRecoverPassword = () => {
        if (inputUser.current.value === "") {
            alert("Debe ingresar un usuario");
            return;
        }

        if (!window.confirm(`¿Desea reestablecer la contraseña de la cuenta "${inputUser.current.value}"?`))
            return;

        serviceFetch(`reestablecer_contrasena/${inputUser.current.value}`)
            .then((res) => {
                if (res.status === 200) {
                    alert("Se ha enviado un correo con las instrucciones para reestablecer su contraseña");
                } else {
                    throw new Error("No se ha podido reestablecer la contraseña");
                }
            })
            .catch((error) => {
                console.log(error);
                alert(error.message);
            });
    };

    const handleGoToSignupClick = e => {
        const usernameElement = document.getElementById('username');
        const passwordElement = document.getElementById('password');

        if(usernameElement.value === "" && passwordElement.value === ""){
            setMessage("Debe ingresar un usuario y contraseña");
            return;
        }

        if(usernameElement.value === ""){
            setMessage("Debe ingresar un usuario");
            return;
        }

        if(passwordElement.value.length < 6){
            setMessage("Debe ingresar una contraseña de al menos 6 caracteres");
            return;
        }
        setMessage(null);
        if(tycShow){
            setTycShow(false);
            return handleSignup(e);;
        }
        setTycShow(true);
    };

    const handleCancelar = () => {
        setTycShow(false);
    };

    const handleMobileClick = () => {
        window.location.href = `${landing}/voice`;
    };


    return (
        <div className="LoginView">
            <p><a href={landing}><img src="https://godemos.cl/static/desktop/assets/img/logo.webp" alt="godemos" class="MenuTop-Logo" /></a></p>
            <form onSubmit={handleOnSubmit}>
                <input ref={inputUser} type="text" id="username" name="username" placeholder="Correo electrónico" onKeyUp={e => e.target.value = e.target.value.toLowerCase()} />
                <input type="password" id="password" name="password" placeholder="Contraseña" />
                <button type="submit">Ingresar</button>
                <div className="link boton" onClick={handleGoToSignupClick}>Regístrate Fácil</div>
                <div className="link" onClick={handleRecoverPassword}><KeyFillIcon />&nbsp;Reestablecer contraseña</div>
                <div className="link" onClick={handleMobileClick} title="Versión Móvil"><PhoneIcon /></div>
                {tycShow && <div className="SignupModal">
                    <p>Nombre de Usuario:<br/><b>{document.getElementById('username').value}</b></p>
                    <p>Contraseña:<br/><span className="password">{document.getElementById('password').value}<i><EyeIcon size="1em" /></i></span></p>
                    <hr/>
                    <p>Al registrarse en GoDemos.cl, usted acepta los <a href="https://godemos.cl/static/terminos_y_condiciones_generales_de_uso.pdf">términos y condiciones</a> de uso del sitio.</p>
                    <button type="button" onClick={handleSignup}>Aceptar y Registrarse</button>
                    <button type="button" className="cancelar" onClick={handleCancelar}>Cancelar</button>
                </div>}
            </form>
        </div>
    );
}

export default LoginView;
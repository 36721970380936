import React from "react";
import { useFetch } from "../hooks/useFetch";
import AudioElementComponent from "./AudioElementComponent";
import { DownloadIcon } from "../components/BootstrapIcons";
import '../styles/components/DemoCardComponent.css';

const DemoCardComponent = ({ demo }) => {

    const { data: res, error, loading } = useFetch(`admin_locutor/${demo.perfil_id}`);

    return (
        
        <div className="DemoCard">
            {
            loading ?
                <p>Cargando...</p>
            : error ?
                <p>Error: {error}</p>
            : !loading && res && demo !== null &&
                    <>
                        <DemoCardPerfil demo={demo} locutor={res.locutor} />
                        <AudioElementComponent audioTrack={demo.path_mezclado} playOnLoad={true} />
                        <a href={`http://localhost:8000${demo.path_mezclado}`} download="download" target="_blank" rel="noreferrer" style={{
                            'color': 'white',
                            'display': 'flex',
                            'margin': '20px auto',
                            'text-decoration': 'none',
                        }}>
                            <DownloadIcon size="24px" /> Descargar
                        </a>
                    </>
            }
        </div>
    );
};

const DemoCardPerfil = ({ demo, locutor }) => {
    return (
        <div className="DemoCardPerfil">
            <span className="DemoCardPerfil__nombre">{demo.perfil}</span>
            <div className="DemoCardPerfil__foto">
                <img src={`http://localhost:8000${locutor.avatar}`} alt={demo.perfil} />
            </div>
            <div className="DemoCardPerfil__datos">
                <span><sub>username: </sub>{locutor.username}</span>
                <span><sub>descripción: </sub>{locutor.descripcion}</span>
                <span><sub>plan: </sub>{locutor.plan}</span>
                <span><sub>país: </sub>{locutor.pais}</span>
                <span><sub>acento: </sub>{locutor.acento}</span>
            </div>
            <span className="DemoCardPerfil__configuracion">
                <span>{demo.tipo}</span>
                <span>{demo.edad}</span>
                <span>{demo.emocion}</span>
            </span>
        </div>
    );
};

export default DemoCardComponent;
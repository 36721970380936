import React from "react";
import { useState } from "react";
import '../styles/views/DemosView.css';
import { useFetch } from "../hooks/useFetch";
import DemoCardComponent from "../components/DemoCardComponent";
import DemoListComponent from "../components/DemoListComponent";

const DemosView = ({ setActivity }) => {

    const { loading, data, error } = useFetch("admin_demo_list/");
    
    const [demo, setDemo] = useState(null);

    return (
        <div className="DemosView">
            {loading && <p>Cargando...</p>}
            {error && <p>Error: {error}</p>}
            {data && <>
                <DemoListComponent demos={data.collection} setDemo={setDemo} demoSelected={demo} />
                {demo && <DemoCardComponent demo={demo} />}
            </>}
        </div>
    );
};





export default DemosView;
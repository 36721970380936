import "../styles/views/LocutoresView.css";
import { useFetch } from "../hooks/useFetch";
import { useState } from "react";
import { FilterIcon } from "../components/BootstrapIcons.jsx";
import PaginateComponent from "../components/PaginateComponent";
import ModalContainer from "../containers/ModalContainer";
import RecordFieldElement from "../containers/RecordFieldElement_deprecated.jsx";

const equipo_options = [
    { value: "Estudio", label: "Estudio Profesional" },
    { value: "Casa", label: "Home Studio" },
    { value: "Movil", label: "Móvil" },
];

const experiencia_options = [
    { value: "S", label: "Con experiencia" },
    { value: "N", label: "Sin experiencia" },
];

const tiempo_respuesta_options = [
    { value: "Horas", label: "Horas" },
    { value: "24 horas", label: "24 horas" },
    { value: "Días", label: "Días" },
];

const plan_options = [
    { value: "free", label: "Free" },
    { value: "basic", label: "Basic" },
    { value: "full", label: "Full" },
    { value: "premium", label: "Premium" },
    { value: "pro", label: "Professional" },
];

const LocutoresView = ({ setActivity }) => {
    const [page, setPage] = useState("1");
    const [filterParams, setFilterParams] = useState({});
    const [displayFilter, setDisplayFilter] = useState(false);

    const { data, loading, error } = useFetch("admin_locutor/?page=" + page, filterParams, [filterParams]);
    return (
        <div className="LocutoresView">
            {loading && <p>Cargando Locutores...</p>}
            {data &&
                <>
                    <ModalContainer display={displayFilter} setDisplay={setDisplayFilter}>
                        <LocutoresFilter setFilterParams={setFilterParams} setDisplayFilter={setDisplayFilter} />
                    </ModalContainer>
                    <LocutoresList data={data} setDisplayFilter={setDisplayFilter} />
                    <PaginateComponent setPage={setPage} page_number={data.page_number} num_pages={data.num_pages} total_items={data.total_items} />
                    <FilterIcon onClick={() => setDisplayFilter(true)} />
                </>
            }
            {error && <p>Ocurrió un error al cargar los Locutores</p>}
        </div>
    );
}

const LocutoresFilter = ({ setFilterParams, setDisplayFilter }) => {
    const handleOnSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData(e.target);
        setFilterParams({
            method: "POST",
            body: formData,
        });
        setDisplayFilter(false);
    }
    return (
        <form className="LocutoresFilter" onSubmit={handleOnSubmit}>
            <label htmlFor="nombre">Nombre</label>
            <input type="text" id="nombre" name="nombre" />

            <label htmlFor="experiencia">Experiencia</label>
            <select name="experiencia" id="experiencia">
                <option value="">Todas</option>
                {
                    experiencia_options.map((opt) => (
                        <option key={opt.value} value={opt.value}>{opt.label}</option>
                    ))
                }
            </select>

            <label htmlFor="anio_carrera">Años de Carrera</label>
            <input type="number" id="anio_carrera" name="anio_carrera" />

            <label htmlFor="equipo">Equipo</label>
            <select name="equipo" id="equipo">
                <option value="">Todas</option>
                {
                    equipo_options.map((opt) => (
                        <option key={opt.value} value={opt.value}>{opt.label}</option>
                    ))
                }
            </select>

            <label htmlFor="tiempo_respuesta">Tiempo de Respuesta</label>
            <select name="tiempo_respuesta" id="tiempo_respuesta">
                <option value="">Todas</option>
                {
                    tiempo_respuesta_options.map((opt) => (
                        <option key={opt.value} value={opt.value}>{opt.label}</option>
                    ))
                }
            </select>

            <label htmlFor="plan">Plan</label>
            <select name="plan" id="plan">
                <option value="">Todas</option>
                {
                    plan_options.map((opt) => (
                        <option key={opt.value} value={opt.value}>{opt.label}</option>
                    ))
                }
            </select>

            <button type="submit">Filtrar</button>
        </form>
    );
};

const LocutoresList = ({ data }) => {
    const columnas = ["usuario", "nombre", "experiencia", "anio_carrera", "equipo", "tiempo_respuesta", "plan", "pais", "acento", "grabó", "fecha de registro", ""];
    return (
        <>
            <div className="LocutoresList">
                <table>
                    <thead>
                        <tr>
                            {columnas.map((col, index) => (
                                <th key={col + index}>{col}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.collection.map((locutor) => (
                            <LocutorRow key={locutor.id} locutor={locutor} />
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

const LocutorRow = ({ locutor }) => {
    // eslint-disable-next-line
    const [allowEdit, setAllowEdit] = useState(true);

    let created = new Date(locutor.created);

    return (
        <>
            <tr key={locutor.id} className={allowEdit ? "LocutorRowEdit" : ""}>
                <td><RecordLocutorField allowEdit={allowEdit} recordId={locutor.id} name="username" value={locutor.username} /></td>
                <td><RecordLocutorField allowEdit={allowEdit} recordId={locutor.id} name="nombre" value={locutor.nombre} /></td>
                <td>
                    <RecordLocutorField allowEdit={allowEdit} recordId={locutor.id} name="experiencia" value={locutor.experiencia} options={experiencia_options} />
                </td>
                <td><RecordLocutorField allowEdit={allowEdit} recordId={locutor.id} name="anio_carrera" value={locutor.anio_carrera} /></td>
                <td><RecordLocutorField allowEdit={allowEdit} recordId={locutor.id} name="equipo" value={locutor.equipo} options={equipo_options} /></td>
                <td><RecordLocutorField allowEdit={allowEdit} recordId={locutor.id} name="tiempo_respuesta" value={locutor.tiempo_respuesta} options={tiempo_respuesta_options} /></td>
                <td><RecordLocutorField allowEdit={allowEdit} recordId={locutor.id} name="plan" value={locutor.plan} options={plan_options} /></td>
                <td><RecordLocutorField allowEdit={allowEdit} recordId={locutor.id} name="pais" value={locutor.pais} /></td>
                <td><RecordLocutorField allowEdit={allowEdit} recordId={locutor.id} name="acento" value={locutor.acento} /></td>
                <td><RecordLocutorField allowEdit={false} value={locutor.grabo} /></td>
                <td><RecordLocutorField allowEdit={false} value={created.toLocaleString()} /></td>
                <td></td>
            </tr>
        </>
    )
};


const RecordLocutorField = ({ name, value, allowEdit = true, recordId, options = null }) => {
    return (
        <RecordFieldElement name={name} value={value} allowEdit={allowEdit} url={`admin_locutor/${recordId}/edit`} options={options} />
    );
};

export default LocutoresView;
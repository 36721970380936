
import React, { useState } from "react";
import { useFetch } from "../hooks/useFetch";
import { InputLiveUpdate, SelectLiveUpdate } from "../components/LiveUpdateFields";
import PieChart from "../components/PieChart";
import { DashSquareIcon, FilterIcon, PlusSquareIcon } from "../components/BootstrapIcons";
import { serviceFetch } from "../utils/serviceFetch";


const Clients = ({
    tiposResponse,
    paisesResponse,
    estadosResponse,
}) => {

    const filterFormId = "filter_clientes_form";

    return (
        <div className="Clients">
            <Filters formId={filterFormId}
                tiposResponse={tiposResponse}
                paisesResponse={paisesResponse}
                estadosResponse={estadosResponse}
            />
        </div>
    );
};

const Filters = ({ formId, tiposResponse, paisesResponse, estadosResponse }) => {


    const handleSubmit = (e) => {
        e.preventDefault();
    };

    let loading = false;

    return (
        <>
            <FilterIcon onClick={() => {
                let filters = document.querySelector(".Filters");
                filters.style.display = filters.style.display === "none" ? "block" : "none";
            }}/>
            <div className="Filters slideIn">
                <h1>Filtros</h1>
                {loading && <p>Cargando datos...</p>}
                {!loading &&
                    <form id={formId} onSubmit={handleSubmit}>
                        <label htmlFor="nombre">Nombre</label>
                        <input type="text" name="nombre" />
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" />
                        <label htmlFor="telefono">Telefono</label>
                        <input type="text" name="telefono" />
                        <label htmlFor="representante">Representante</label>
                        <input type="text" name="representante" />
                        <label htmlFor="pais">Pais</label>
                        <select name="pais">
                            <option value="">Todos</option>
                            {paisesResponse.paises.map((p) => <option key={p.id} value={p.id}>{p.nombre}</option>)}
                        </select>
                        <label htmlFor="tipo_cliente">Tipo de cliente</label>
                        <select name="tipo_cliente">
                            <option value="">Todos</option>
                            {tiposResponse.tipos.map((t) => <option key={t.id} value={t.id}>{t.nombre}</option>)}
                        </select>
                        <label htmlFor="estado_cliente">Estado de cliente</label>
                        <select name="estado_cliente">
                            <option value="">Todos</option>
                            {estadosResponse.estados.map((e) => <option key={e.id} value={e.id}>{e.nombre}</option>)}
                        </select>
                        <div className="buttons">

                            <button type="submit">Filtrar</button>
                            <button type="reset">Limpiar</button>
                        </div>
                    </form>
                }
            </div>
            {!loading && <Results formId={formId} filters={{
                tipos: tiposResponse.tipos,
                estados: estadosResponse.estados,
                paises: paisesResponse.paises,
            }} />}
        </>
    );
}

const Results = ({ formId, filters }) => {
    const [path, setPath] = useState("get_clientes");

    const filterForm = document.getElementById(formId);

    const [loadIterator, setLoadIterator] = useState(0);

    const { data: registrosResponse, loading: registrosLoading } = useFetch(path, {
        method: "POST",
        body: filterForm ? new FormData(filterForm) : null,
    }, [loadIterator]);

    if (filterForm) {
        filterForm.onsubmit = (e) => {
            e.preventDefault();
            setLoadIterator(loadIterator + 1);
        };
    }

    return (
        <div className="Results" >
            {registrosLoading && <p>Cargando datos...</p>}
            {!registrosLoading && <>
                <Charts registros={registrosResponse.clientes} filters={filters} formId={formId} />
                <Data registrosReponse={registrosResponse} filters={filters} setRequestURL={setPath} />
            </>}
        </div>
    );
};

const Charts = ({ registros, filters, formId }) => {
    const estados = filters.estados;
    const tipos = filters.tipos;
    const paises = filters.paises;

    const form = document.getElementById(formId);

    const estadosData = estados.map((e) => {
        let count = registros.filter((r) => r.estado_cliente.id === e.id).length;
        return {
            id: e.id, name: e.nombre, value: count, onClick: () => {
                let input = form.querySelector("select[name='estado_cliente']");
                input.value = e.id;
                form.dispatchEvent(new Event("submit"));
            }
        };
    });

    const tiposData = tipos.map((t) => {
        let count = registros.filter((r) => r.tipo_cliente.id === t.id).length;
        return {
            id: t.id, name: t.nombre, value: count, onClick: () => {
                let input = form.querySelector("select[name='tipo_cliente']");
                input.value = t.id;

                form.dispatchEvent(new Event("submit"));
            }
        };
    });

    const paisesData = paises.map((p) => {
        let count = registros.filter((r) => r.pais.id === p.id).length;
        return {
            id: p.id, name: p.nombre, value: count, onClick: () => {
                let input = form.querySelector("select[name='pais']");
                input.value = p.id;
                form.dispatchEvent(new Event("submit"));
            }
        };
    });

    return (
        <div className="Charts" style={{
            display: "inline-flex"
        }}>
            <PieChart serie={estadosData} radio={75} />
            <span style={{ display: "inline-block", width: "75px" }}></span>
            <PieChart serie={tiposData} radio={75} />
            <span style={{ display: "inline-block", width: "75px" }}></span>
            <PieChart serie={paisesData} radio={75} />
        </div>
    );
};

const Data = ({ registrosReponse, filters, setRequestURL }) => {

    return (
        <div className="Data">
            <Pagination />
            <Table registros={registrosReponse.clientes}
                estados={filters.estados}
                tipos={filters.tipos}
                paises={filters.paises}
                setRequestURL={setRequestURL}
            />
        </div>
    );
};

const Table = ({ registros, estados, tipos, paises, setRequestURL }) => {
    const tableWidth = {
        nombre: "250px",
        email: "150px",
        telefono: "150px",
        representante: "150px",
        pais: "200px",
        tipo_cliente: "150px",
        estado_cliente: "200px",
    };

    const handleSort = (e) => {
        // const campo = e.target.getAttribute("data-campo");
        const orden = e.target.getAttribute("data-orden") || "asc";
        const newOrden = orden === "asc" ? "desc" : "asc";

        let thPosition = Array.from(e.target.closest("tr").children).indexOf(e.target);

        let tbody = e.target.closest("table").querySelector("tbody");
        let rows = Array.from(tbody.rows);

        rows.sort((a, b) => {
            // console.log(a,thPosition,`td:nth-child(${thPosition})`,a.querySelector(`td:nth-child(${thPosition})`));
            let aValue = a.querySelector(`td:nth-child(${thPosition + 1}) input, td:nth-child(${thPosition + 1}) select`).value;
            let bValue = b.querySelector(`td:nth-child(${thPosition + 1}) input, td:nth-child(${thPosition + 1}) select`).value;

            if (orden === "asc") {
                return aValue > bValue ? 1 : -1;
            } else {
                return aValue < bValue ? 1 : -1;
            }
        });

        // if (newOrden === "desc") {
        //     rows.reverse();
        // }

        tbody.innerHTML = "";
        rows.forEach((r) => tbody.appendChild(r));

        e.target.setAttribute("data-orden", newOrden);
    };

    const [refresh, setRefresh] = useState(0);

    const addCliente = () => {
        serviceFetch("add_cliente", "PUT").then((data) => {
            if (data.ok) {
                return data.json();
            }
            data.json().then((json) => {
                if(json.success === 0){
                    alert(json.message);
                }
            });
        }).then((data) => {
            if (data) {
                // registros.push(data.registro) as first element
                registros.unshift(data.cliente);
                setRefresh(refresh + 1);
            }
        });
    };

    const deleteCliente = (id) => {
        if (!window.confirm("¿Está seguro de eliminar este cliente?")) {
            return;
        }
        serviceFetch(`delete_cliente/${id}`, "DELETE").then((data) => {
            if (data.ok) {
                let index = registros.findIndex((r) => r.id === id);
                registros.splice(index, 1);
                setRefresh(refresh + 1);
            }
        });
    };

    return (
        <table key={`cliente_${refresh}`}>
            <thead style={{ position: 'sticky', top: -20 }}>
                <tr>
                    <th><PlusSquareIcon onClick={addCliente} /></th>
                    <th style={{ width: tableWidth.nombre }} onClick={handleSort} data-orden="asc">Nombre</th>
                    <th style={{ width: tableWidth.email }} onClick={handleSort} data-orden="asc">Email</th>
                    <th style={{ width: tableWidth.telefono }} onClick={handleSort} data-orden="asc">Telefono</th>
                    <th style={{ width: tableWidth.representante }} onClick={handleSort} data-orden="asc">Representante</th>
                    <th style={{ width: tableWidth.pais }} onClick={handleSort} data-orden="asc">Pais</th>
                    <th style={{ width: tableWidth.tipo_cliente }} onClick={handleSort} data-orden="asc">Tipo de cliente</th>
                    <th style={{ width: tableWidth.estado_cliente }} onClick={handleSort} data-orden="asc">Estado de cliente</th>
                </tr>
            </thead>
            <tbody>
                {registros.map((r) => (
                    <tr key={r.id}>
                        <td><DashSquareIcon onClick={() => deleteCliente(r.id)} /></td>
                        <td style={{ width: tableWidth.nombre }}>
                            <InputLiveUpdate
                                value={r.nombre}
                                url={`edit_cliente/nombre/${r.id}`}
                                onUpdate={(v) => { r.nombre = v; }}
                                style={{
                                    minWidth: tableWidth.nombre,
                                    width: tableWidth.nombre
                                }} />
                        </td>
                        <td style={{ width: tableWidth.email }}>
                            <InputLiveUpdate
                                value={r.email}
                                url={`edit_cliente/email/${r.id}`}
                                onUpdate={(v) => { r.email = v; }}
                                style={{
                                    minWidth: tableWidth.email,
                                    width: tableWidth.email
                                }} />
                        </td>
                        <td style={{ width: tableWidth.telefono }}>
                            <InputLiveUpdate
                                value={r.telefono}
                                url={`edit_cliente/telefono/${r.id}`}
                                onUpdate={(v) => { r.telefono = v; }}
                                style={{
                                    minWidth: tableWidth.telefono,
                                    width: tableWidth.telefono
                                }} />
                        </td>
                        <td style={{ width: tableWidth.representante }}>
                            <InputLiveUpdate
                                value={r.representante}
                                url={`edit_cliente/representante/${r.id}`}
                                onUpdate={(v) => { r.representante = v; }}
                                style={{
                                    minWidth: tableWidth.representante,
                                    width: tableWidth.representante
                                }} />
                        </td>
                        <td style={{ width: tableWidth.pais }}>
                            <SelectLiveUpdate
                                value={r.pais.id}
                                url={`edit_cliente/pais/${r.id}`}
                                onUpdate={(v) => { r.pais.id = v; }}
                                options={paises}
                                style={{
                                    minWidth: tableWidth.pais,
                                    width: tableWidth.pais
                                }} />
                        </td>
                        <td style={{ width: tableWidth.tipo_cliente }}>
                            <SelectLiveUpdate
                                value={r.tipo_cliente.id}
                                url={`edit_cliente/tipo_cliente/${r.id}`}
                                onUpdate={(v) => { r.tipo_cliente.id = v; }}
                                options={tipos}
                                style={{
                                    minWidth: tableWidth.tipo_cliente,
                                    width: tableWidth.tipo_cliente
                                }} />
                        </td>
                        <td style={{ width: tableWidth.estado_cliente }}>
                            <SelectLiveUpdate
                                value={r.estado_cliente.id}
                                url={`edit_cliente/estado_cliente/${r.id}`}
                                onUpdate={(v) => { r.estado_cliente.id = v; }}
                                options={estados}
                                style={{
                                    minWidth: tableWidth.estado_cliente,
                                    width: tableWidth.estado_cliente
                                }} />
                        </td>

                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const Pagination = () => {
    return (
        <div className="Pagination">
        </div>
    );
};

export default Clients;
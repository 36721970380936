import React, { useRef } from "react";
import { useFetch } from "../hooks/useFetch";
import { backendRoot } from "../contexts/BackendProps";
import { CloseIcon, EnvelopeExclamationIcon, PathCheckIcon } from "../components/BootstrapIcons";
import { ChoicesLiveUpdate, InputLiveUpdate, SelectLiveUpdate } from "../components/LiveUpdateFields";
import { serviceFetch } from "../utils/serviceFetch";

const ProfileContainer = ({ setGeneroVoz = (voz) => { } }) => {

  const ProfileEdit = useRef(null);
  const avatar = useRef(null);
  const avatarEdit = useRef(null);


  const { data, error, loading } = useFetch('get_perfil');
  const { data: paisesResponse, error: paisesError, loading: paisesLoading } = useFetch('get_paises');
  const { data: acentosResponse, error: acentosError, loading: acentosLoading } = useFetch('get_acentos');

  const [refresh, setRefresh] = React.useState(0);

  const verifiedStyle = {
    color: 'var(--green-9)'
  };
  const unVerifiedStyle = {
    color: 'var(--secondary-7)',
    cursor: 'pointer'
  };

  return (
    <div className='Profile'>
      {loading && <div className='loading'>Loading...</div>}
      {error && <div className='error'>{error}</div>}
      {data && (
        <>
          {setGeneroVoz(data.voz)}
          <div className='Profile-view'>
            <div>
              {data.verificado && <span><PathCheckIcon size="16" style={verifiedStyle} /></span>}
              &nbsp;{data.username}
              <h1>{data.nombre}</h1>
              <span style={{ whiteSpace: "pre-wrap" }}>{data.descripcion}</span>
              <div><a target='_blank' rel="noreferrer" href={data.links ? data.links : "https://godemos.cl"}>{data.links}</a></div>
              <div><i>{data.plan}</i></div>
            </div>
            <img
              ref={avatar}
              src={`${backendRoot}/${data.avatar}`}
              alt='avatar' onClick={(e) => { document.getElementById("Profile-edit").style.display = "" }}
              style={{
                clipPath: 'circle()',
                width: '180px',
                marginLeft: "20px",
                cursor: "pointer",
                objectFit: "cover"
              }} />
          </div>

          <div className='Profile-edit' ref={ProfileEdit} id="Profile-edit" style={{ display: "none" }}>
            <div>
              <CloseIcon
                onClick={() => {
                  setRefresh(refresh + 1);
                  ProfileEdit.current.style.display = "none";
                }}
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
            <div style={{ textAlign: 'right' }}>
              <div>{data.username}</div>

              {data.verificado && <span style={verifiedStyle}><PathCheckIcon size="16" /> correo verificado</span>}
              {!data.verificado && <span style={unVerifiedStyle} onClick={(e) => {
                serviceFetch(`confirmar_registro/${data.username}`, "GET")
                  .then(r => r.json())
                  .then(data => {
                    if (data.status !== "success") {
                      alert(data.message);
                    } else {
                      alert("Te hemos enviado un correo para verificar tu cuenta");
                    }
                  })
                  .catch(e => {
                    console.error(e);
                    alert("Ha ocurrido un problema al verificar el correo");
                  });
              }}><EnvelopeExclamationIcon size="16" /> verificar correo</span>}
            </div>

            <label style={{ width: 'min-content' }}>
              <img
                ref={avatarEdit}
                src={`${backendRoot}/${data.avatar}`}
                alt='avatar'
                style={{
                  clipPath: 'circle()',
                  width: '192px',
                  objectFit: "cover",
                  cursor: "pointer",
                }}
              />
              <input type="file" style={{ display: "none" }}
                onChange={e => {
                  if (e.target.files.length === 0) return;
                  avatarEdit.current.src = URL.createObjectURL(e.target.files[0]);
                  let formdata = new FormData();
                  formdata.append("avatar", e.target.files[0]);
                  serviceFetch('edit_perfil/avatar', "POST", formdata)
                    .then(r => r.json())
                    .then(data => {
                      avatarEdit.current.src = `${backendRoot}/${data.valor}`;
                      avatar.current.src = avatarEdit.current.src;
                    })
                    .catch(e => {
                      console.error(e);
                      alert("Ha ocurrido un problema al cambiar la imagen de perfil");
                      avatarEdit.current.src = avatar.current.src;
                    });
                }}
              />
            </label>
            <div>
              <span>Nombre</span>
              <div>
                <InputLiveUpdate type='text' value={data.nombre} url='edit_perfil/nombre' onUpdate={(v) => data.nombre = v} />
              </div>
            </div>
            <div>
              <span>Descripción</span>
              <div>
                <InputLiveUpdate type='text' value={data.descripcion} url='edit_perfil/descripcion' onUpdate={(v) => data.descripcion = v} style={{ height: '200px' }} />
              </div>
            </div>
            <div>
              <span>¿Tienes experiencia en locución?</span>
              <ChoicesLiveUpdate
                options={[{ id: 'N', nombre: 'No' }, { id: 'S', nombre: 'Sí' }]}
                url={"edit_perfil/experiencia"}
                value={data.experiencia}
                onUpdate={(v) => data.experiencia = v}
              />
            </div>
            <div>
              <span>Equipo de Grabación</span>
              <ChoicesLiveUpdate
                options={[
                  { id: 'Movil', nombre: 'Móvil' },
                  { id: 'Casa', nombre: 'Home Studio' },
                  { id: 'Estudio', nombre: 'Estudio Profesional' }
                ]}
                url={"edit_perfil/equipo"}
                value={data.equipo}
                onUpdate={(v) => data.equipo = v}
              />
            </div>
            <div>
              <span>Tiempo de Respuesta</span>
              <ChoicesLiveUpdate
                options={[
                  { id: 'Horas', nombre: 'Horas' },
                  { id: '24 horas', nombre: '24 Horas' },
                  { id: 'Días', nombre: 'Días' }
                ]}
                url={"edit_perfil/tiempo_respuesta"}
                value={data.tiempo_respuesta}
                onUpdate={(v) => data.tiempo_respuesta = v}
              />
            </div>
            <div>
              <span>País</span>
              {paisesLoading && <div className='loading'>Cargando países...</div>}
              {paisesError && <div className='error'>{paisesError}</div>}
              {paisesResponse && (
                <SelectLiveUpdate
                  options={paisesResponse.paises}
                  url={"edit_perfil/pais"}
                  value={data.pais?.id}
                  onUpdate={(v) => data.pais = v}
                />
              )}
            </div>
            <div>
              <span>Acento Nativo</span>
              {acentosLoading && <div className='loading'>Cargando acentos...</div>}
              {acentosError && <div className='error'>{acentosError}</div>}
              {acentosResponse && (
                <SelectLiveUpdate
                  options={acentosResponse.acentos}
                  url={"edit_perfil/acento"}
                  value={data.acento?.id}
                  onUpdate={(v) => data.acento = v}
                />
              )}
            </div>
            <div>
              <span>Género de tu Voz</span>
              <ChoicesLiveUpdate
                options={[
                  { id: 'M', nombre: 'Masculina' },
                  { id: 'F', nombre: 'Femenina' },
                ]}
                url={"edit_perfil/voz"}
                value={data.voz}
                onUpdate={(v) => data.voz = v}
              />
            </div>
            <div>
              <span>Estilo del Perfil (<a target='_black' href={`${backendRoot}/perfil/${data.username}`}>ver perfil</a>)</span>
              <ChoicesLiveUpdate
                options={[
                  { id: 'Rock', nombre: 'Rock' },
                  { id: '90s', nombre: '90s' },
                  { id: 'Blue', nombre: 'Blue' }
                ]}
                url={"edit_perfil/diseno"}
                value={data.diseno}
                onUpdate={(v) => data.diseno = v}
              />
            </div>
            <div>
              <span>Página web</span>
              <small> (copie y pegue la URL de su sitio)</small>
              <div>
                <InputLiveUpdate type='text' value={data.links} url='edit_perfil/links' onUpdate={(v) => data.links = v} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileContainer;

import React, { useState } from 'react';
import { useFetch } from '../hooks/useFetch.jsx';
import { CashCoinIcon, CloseIcon, EyeIcon, MicIcon, PeopleIcon, ReceiptCutoffIcon, TelephoneIcon } from '../components/BootstrapIcons.jsx';
import { InputLiveUpdate, SelectLiveUpdate } from '../components/LiveUpdateFields.jsx';

/**
 * Renders the Fechas component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.estadosRegistroResponse - The response object containing the registration states.
 * @param {Object} props.tiposRegistroResponse - The response object containing the registration types.
 * @param {Object} props.derechosResponse - The response object containing the rights.
 * @param {Object} props.tiempoDeUsoResponse - The response object containing the usage time.
 * @param {Object} props.tiposDocumentoResponse - The response object containing the document types.
 * @param {Object} props.tiposClienteResponse - The response object containing the client types.
 * @param {Object} props.paisesResponse - The response object containing the countries.
 * @param {Object} props.estadosClienteResponse - The response object containing the client states.
 * @returns {JSX.Element} The rendered Fechas component.
 */
const Fechas = ({ estadosRegistroResponse, tiposRegistroResponse, derechosResponse, tiempoDeUsoResponse, tiposDocumentoResponse, tiposClienteResponse, paisesResponse, estadosClienteResponse }) => {

    const [registro, setRegistro] = useState(null);
    const [cliente, setCliente] = useState(null);

    const { data: registrosResponse, loading: resistrosLoading } = useFetch("get_registros_crm/id/desc", {
        method: "POST",
        body: null
    });

    let loading = resistrosLoading;

    let fechas_registros = [];
    if (!loading) {
        let registros = registrosResponse.registros;
        registros.forEach(registro => {
            let fechas = [
                { tipo_fecha: "Grabacion", icono:(<MicIcon size="24px" />), fecha: registro.fecha_grabacion },
                { tipo_fecha: "Facturación", icono:(<ReceiptCutoffIcon size="24px" />), fecha: registro.fecha_facturacion },
                { tipo_fecha: "Cobro", icono:(<TelephoneIcon size="24px" />), fecha: registro.fecha_cobro },
                { tipo_fecha: "Pago", icono:(<CashCoinIcon size="24px" />), fecha: registro.fecha_pago }
            ];
            fechas.forEach(fecha_elem => {
                let fecha = fecha_elem.fecha;
                let tipo_fecha = fecha_elem.tipo_fecha;
                if (fecha === null) return;
                let aux = {
                    registro_id: registro.id,
                    fecha: fecha,
                    tipo: tipo_fecha,
                    icono: fecha_elem.icono,
                    tipo_registro: registro.tipo,
                    nombre: registro.descripcion,
                    estado: registro.estado,
                    r: registro
                };
                fechas_registros.push(aux);
            });
        });
    }


    return (
        <div className='Fechas'>
            {loading && <div>Loading...</div>}
            {!loading && <>
                <VistaFechas registros={fechas_registros} showRegistro={setRegistro} showCliente={setCliente} />
                {registro && <VistaRegistro registro={registro}
                    setRegistro={setRegistro}
                    estadosRegistroResponse={estadosRegistroResponse}
                    tiposRegistroResponse={tiposRegistroResponse}
                    derechosResponse={derechosResponse}
                    tiempoDeUsoResponse={tiempoDeUsoResponse}
                    tiposDocumentoResponse={tiposDocumentoResponse}
                />}
                {cliente && <VistaCliente cliente_id={cliente}
                    setCliente={setCliente}
                    tiposClienteResponse={tiposClienteResponse}
                    paisesResponse={paisesResponse}
                    estadosClienteResponse={estadosClienteResponse}
                />}
            </>}
        </div>
    );
};

/**
 * Renders a component that displays future and past dates.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.registros - The array of date records.
 * @param {Function} props.showRegistro - The function to show a specific date record.
 * @param {Function} props.showCliente - The function to show a specific client.
 * @returns {JSX.Element} The rendered component.
 */
const VistaFechas = ({ registros, showRegistro, showCliente }) => {
    let fechas_futuras = registros.filter(registro => new Date(registro.fecha) >= new Date());
    let fechas_pasadas = registros.filter(registro => new Date(registro.fecha) < new Date());

    fechas_futuras.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
    fechas_pasadas.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));

    return (<>
        <div className='VistaFechas'>
            <h2>Fechas Futuras</h2>
            <div>
                {fechas_futuras.map(registro => <RegistroFecha key={Math.random()} registro={registro} showRegistro={showRegistro} showCliente={showCliente} />)}
            </div>
        </div>
        <div className='VistaFechas'>
            <h2>Fechas Pasadas</h2>
            <div>
                {fechas_pasadas.map(registro => <RegistroFecha key={Math.random()} registro={registro} showRegistro={showRegistro} showCliente={showCliente} />)}
            </div>
        </div>
    </>);
};

/**
 * Renders a single registro fecha component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.registro - The registro object.
 * @param {Function} props.showRegistro - The function to show the registro.
 * @param {Function} props.showCliente - The function to show the cliente.
 * @returns {JSX.Element} The rendered component.
 */
const RegistroFecha = ({ registro, showRegistro, showCliente }) => {
    return (
        <div className='RegistroFecha'>
            <div>{registro.fecha}</div>
            <div>{registro.icono} {registro.tipo}</div>
            <div>{registro.nombre}</div>
            <div>{registro.estado.nombre}</div>
            <div><EyeIcon onClick={() => showRegistro(registro.r)} /> <PeopleIcon onClick={() => showCliente(registro.r.cliente.id)} /></div>
        </div>
    );
};

/**
 * Renders the VistaRegistro component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.registro - The registro object.
 * @param {Function} props.setRegistro - The function to set the registro object.
 * @param {Object} props.estadosRegistroResponse - The estadosRegistroResponse object.
 * @param {Object} props.tiposRegistroResponse - The tiposRegistroResponse object.
 * @param {Object} props.derechosResponse - The derechosResponse object.
 * @param {Object} props.tiempoDeUsoResponse - The tiempoDeUsoResponse object.
 * @param {Object} props.tiposDocumentoResponse - The tiposDocumentoResponse object.
 * @returns {JSX.Element} The rendered VistaRegistro component.
 */
const VistaRegistro = ({ registro, setRegistro, estadosRegistroResponse, tiposRegistroResponse, derechosResponse, tiempoDeUsoResponse, tiposDocumentoResponse }) => {

    return (
        <>
            {registro && <div className="VistaRegistro">
                <CloseIcon onClick={() => setRegistro(null)} />
                <div>
                    <label>Descripción</label>
                    <InputLiveUpdate value={registro.descripcion} url={`edit_registro_crm/descripcion/${registro.id}`}
                        onUpdate={(v) => registro.descripcion = v}
                    />
                </div>
                <div>
                    <label>Estado</label>
                    <SelectLiveUpdate value={registro.estado.id} url={`edit_registro_crm/estado/${registro.id}`} options={estadosRegistroResponse.estados}
                        onUpdate={(v) => registro.estado = v}
                    />
                </div>
                <div>
                    <label>Tipo</label>
                    <SelectLiveUpdate value={registro.tipo.id} url={`edit_registro_crm/tipo/${registro.id}`} options={tiposRegistroResponse.tipos}
                        onUpdate={(v) => registro.tipo = v}
                    />
                </div>
                <div>
                    <label>Derecho</label>
                    <SelectLiveUpdate value={registro.derechos.id} url={`edit_registro_crm/derechos/${registro.id}`} options={derechosResponse.derechos}
                        onUpdate={(v) => registro.derechos = v}
                    />
                </div>
                <div>
                    <label>Tiempo de uso</label>
                    <SelectLiveUpdate value={registro.tiempo_de_uso.id} url={`edit_registro_crm/tiempo_de_uso/${registro.id}`} options={tiempoDeUsoResponse.tiempos}
                        onUpdate={(v) => registro.tiempo_de_uso = v}
                    />
                </div>
                <div>
                    <label>Fecha de grabación</label>
                    <InputLiveUpdate type='date' value={registro.fecha_grabacion} url={`edit_registro_crm/fecha_grabacion/${registro.id}`}
                        onUpdate={(v) => registro.fecha_grabacion = v}
                    />
                </div>
                <div>
                    <label>Fecha de facturación</label>
                    <InputLiveUpdate type='date' value={registro.fecha_facturacion} url={`edit_registro_crm/fecha_facturacion/${registro.id}`}
                        onUpdate={(v) => registro.fecha_facturacion = v}
                    />
                </div>
                <div>
                    <label>Fecha de cobro</label>
                    <InputLiveUpdate type='date' value={registro.fecha_cobro} url={`edit_registro_crm/fecha_cobro/${registro.id}`}
                        onUpdate={(v) => registro.fecha_cobro = v}
                    />
                </div>
                <div>
                    <label>Fecha de pago</label>
                    <InputLiveUpdate type='date' value={registro.fecha_pago} url={`edit_registro_crm/fecha_pago/${registro.id}`}
                        onUpdate={(v) => registro.fecha_pago = v}
                    />
                </div>
                <div>
                    <label>Cliente</label>
                    <span>{registro.cliente.nombre}</span>
                </div>
                <div>
                    <label>Valor</label>
                    <span>$ {registro.valor.toLocaleString()}</span>
                </div>
                <div>
                    <label>Número Documento</label>
                    <InputLiveUpdate value={registro.numero_documento} url={`edit_registro_crm/numero_documento/${registro.id}`}
                        onUpdate={(v) => registro.numero_documento = v}
                    />
                </div>
                <div>
                    <label>Documento</label>
                    <SelectLiveUpdate value={registro.tipo_documento.id} url={`edit_registro_crm/tipo_documento/${registro.id}`} options={tiposDocumentoResponse.tipos}
                        onUpdate={(v) => registro.tipo_documento = v}
                    />
                </div>
            </div>
            }
        </>
    );
};

/**
 * Renders the VistaCliente component.
 *
 * @param {Object} props - The component props.
 * @param {number} props.cliente_id - The ID of the client.
 * @param {function} props.setCliente - The function to set the client.
 * @param {Object} props.tiposClienteResponse - The response object containing the types of clients.
 * @param {Object} props.paisesResponse - The response object containing the countries.
 * @param {Object} props.estadosClienteResponse - The response object containing the client states.
 * @returns {JSX.Element} The rendered VistaCliente component.
 */
const VistaCliente = ({ cliente_id, setCliente, tiposClienteResponse, paisesResponse, estadosClienteResponse }) => {
    const { data: clienteResponse, loading: clienteLoading } = useFetch(`get_cliente/${cliente_id}`);

    return (
        <div className='VistaRegistro'>
            {clienteLoading && <div>Loading...</div>}
            {!clienteLoading && <>
                <CloseIcon onClick={() => setCliente(null)} />
                <div>
                    <label>Nombre</label>
                    <InputLiveUpdate value={clienteResponse.nombre} url={`edit_cliente/nombre/${cliente_id}`} onUpdate={(v) => clienteResponse.nombre = v} />
                </div>
                <div>
                    <label>Tipo</label>
                    <SelectLiveUpdate value={clienteResponse.tipo_cliente?.id} url={`edit_cliente/tipo_cliente/${cliente_id}`} options={tiposClienteResponse.tipos} onUpdate={(v) => clienteResponse.tipo_cliente.id = v} />
                </div>
                <div>
                    <label>País</label>
                    <SelectLiveUpdate value={clienteResponse.pais?.id} url={`edit_cliente/pais/${cliente_id}`} options={paisesResponse.paises} onUpdate={(v) => clienteResponse.pais.id = v} />
                </div>
                <div>
                    <label>Estado</label>
                    <SelectLiveUpdate value={clienteResponse.estado_cliente?.id} url={`edit_cliente/estado_cliente/${cliente_id}`} options={estadosClienteResponse.estados} onUpdate={(v) => clienteResponse.estado_cliente.id = v} />
                </div>
                <div>
                    <label>Estado</label>
                    <InputLiveUpdate value={clienteResponse.email} url={`edit_cliente/email/${cliente_id}`} onUpdate={(v) => clienteResponse.email = v} />
                </div>
            </>}
        </div>
    );
};
export default Fechas;
import React, { useRef, useState } from "react";

import "../styles/views/ReelsView.css";
import { CloseIcon, CloudArrowUp, PlusCircleIcon, SpeakerIcon, TrashFillIcon } from "../components/BootstrapIcons";
import { useFetch } from "../hooks/useFetch";
import AudioElementComponent from "../components/AudioElementComponent";
import { serviceFetch } from "../utils/serviceFetch";

const ReelsView = ({ setActivity }) => {

    const [reelUpload, setReelUpload] = useState(false);

    const [reload, setReload] = useState(0);

    const { data, loading, error } = useFetch("reels", {}, [reload]);

    return (<>
        <div className="ReelsView">
            <div className="IconLighter">
                <SpeakerIcon size="128px" />
            </div>
            {loading && <p>Cargando...</p>}
            {error && <p>{error}</p>}
            {data?.reels?.length === 0 && <>
                <h1>Reel</h1>
                <p>Sube tus archivos y desde nuestro estudio te enviaremos un reel profesional.</p>
                <button className="Button-I-want" onClick={() => setReelUpload(true)}>Quiero mi Reel!</button>
            </>}
            {data?.reels?.length > 0 && <>
                <h1>Reel</h1>
                <div>
                    {data.reels.map((reel, index) => (
                        <div key={index}>
                            {reel.audio_path && <AudioElementComponent audioTrack={reel.audio_path} />}
                            {!reel.audio_path && <PreparandoReel />}
                        </div>
                    ))}
                </div>
            </>}
        </div>
        {reelUpload && <ReelUpload showReelUpload={setReelUpload} reloadPage={setReload} />}
    </>
    );
};

const PreparandoReel = () => {
    return (<>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className="cassette">
                <span className="spining-clockwise"><PlusCircleIcon size="40px" /></span>
                <span style={{width:'50px'}}></span>
                <span className="spining-clockwise"><PlusCircleIcon size="40px" /></span>
            </div>
            <center>
                Tu Reel ya está siendo trabajado por nuestro equipo de producción.<br />
                En breve lo tendrás disponible.<br /><br />
                Mientras, puedes seguir grabando más demos para tu Demoteca.
            </center>
        </div>
    </>);
};

const ReelUpload = ({ showReelUpload, reloadPage }) => {
    const audio_limit = 10;
    const fileInput = useRef(null);
    const [fileObjectUrl, setFileObjectUrl] = useState(null);

    const [reload, setReload] = useState(0);
    const { data, loading, error } = useFetch("reel_audios", {}, [reload]);

    const handleFileChange = (e) => {
        fileInput.current.file = e.target.files;
        setFileObjectUrl(URL.createObjectURL(fileInput.current.file[0]));
    };

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append("audio", fileInput.current.file[0]);
        const response = await serviceFetch("add_reel_audio", "POST", formData);
        if (response.ok) {
            setReload(reload + 1);
            fileInput.current.value = "";
            setFileObjectUrl(null);
        } else {
            alert("Error al subir el archivo");
        }
    };

    const handleSolicitarReel = async () => {
        const response = await serviceFetch("add_reel", "PUT");
        if (response.ok) {
            reloadPage(reload + 1);
            showReelUpload(false);
        } else {
            alert("Error al solicitar el reel");
        }
    };

    const deleteReelAudio = async (id) => {
        let formData = new FormData();
        formData.append("reel_audio_id", id);
        const response = await serviceFetch("delete_reel_audio", "POST", formData);
        if (response.ok) {
            setReload(reload + 1);
        } else {
            alert("Ha ocurrido un error al eliminar el audio.");
        }
    };

    return (
        <div className="ReelsUpload">
            <CloseIcon size="32px" onClick={() => showReelUpload(false)} style={{cursor:'pointer'}} />
            {error && <p>{error}</p>}
            {data?.reel_audios?.length < audio_limit && <>
                <h2>Sube tus archivos</h2>
                <p>Selecciona los archivos que deseas incluir en tu reel.</p>
                <label className="FileUpload" htmlFor="file">
                    <CloudArrowUp size="64px" />
                    <p>Selecciona archivos</p>
                </label>
                <input
                    type="file"
                    id="file"
                    ref={fileInput}
                    multiple={false}
                    accept="audio/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                />

                {fileObjectUrl && <>
                    <AudioElementComponent audioTrack={fileObjectUrl} />
                    <div className="Button-container">
                        <button onClick={() => showReelUpload(false)}>Cancelar</button>
                        <button onClick={handleUpload}>Subir</button>
                    </div>
                </>}
            </>}
            {data?.reel_audios?.length > 0 && <>
                <h2>Archivos subidos</h2>
                <div className="ReelsUpload-list">
                    {data.reel_audios.map((audio, index) => (
                        <div key={index}>
                            <AudioElementComponent audioTrack={audio.audio_path} />
                            <i><TrashFillIcon size="24px" onClick={()=>deleteReelAudio(audio.id)}/></i>
                        </div>
                    ))}
                </div>
                <button onClick={handleSolicitarReel}>Solicitar Reel</button>
            </>}
            {loading && <p>Actualizando audios...</p>}
        </div>
    );
}

export default ReelsView;
/**
 * Dashboard component for displaying various statistics and charts.
 *
 * @component
 * @param {Object} estadosRegistroResponse - The response object containing estadosRegistro data.
 * @param {Object} tiposRegistroResponse - The response object containing tiposRegistro data.
 * @param {Object} derechosResponse - The response object containing derechos data.
 * @param {Object} tiempoDeUsoResponse - The response object containing tiempoDeUso data.
 * @param {Object} tiposDocumentoResponse - The response object containing tiposDocumento data.
 * @param {Object} tiposClienteResponse - The response object containing tiposCliente data.
 * @param {Object} paisesResponse - The response object containing paises data.
 * @param {Object} estadosClienteResponse - The response object containing estadosCliente data.
 * @returns {JSX.Element} The rendered Dashboard component.
 */
import React from "react";
import PieChart from "../components/PieChart";
import { useFetch } from "../hooks/useFetch";
import { chartColors } from "../contexts/BackendProps";

const radio = 60;

const Dashboard = ({ estadosRegistroResponse, tiposRegistroResponse, derechosResponse, tiempoDeUsoResponse, tiposDocumentoResponse, tiposClienteResponse, paisesResponse, estadosClienteResponse }) => {

    const [mes, setMes] = React.useState(new Date().toISOString().slice(0, 7));
    let formData = new FormData();
    formData.append('mes', new Date().toISOString().slice(0, 7));
    const [body, setBody] = React.useState(formData);
    const { data: registrosResponse, loading: resistrosLoading } = useFetch("get_registros_crm/id/desc", {
        method: "POST",
        body: body
    }, [body]);

    const { data: clientesResponse, loading: clientesLoading } = useFetch("get_clientes");

    let loading = resistrosLoading || clientesLoading;

    const handleFilter = (e) => {
        let formData = new FormData();
        formData.append('mes', document.getElementById('filter_mes').value);
        setBody(formData)
    };

    const handleReset = (e) => {
        document.getElementById('filter_mes').value = '';
        setMes(null);
        setBody(null);
    };

    return (
        <div className="Dashboard">
            <div className="Card Cliente-card" style={{ width: '100%' }}>
                <h2><div className="Dashboard-top-filter">
                    <span>Dashboard</span>
                    <input type="month" id="filter_mes" name="mes" value={mes} onChange={e =>
                        setMes(e.target.value)
                    } />
                    <button onClick={handleFilter}>Filtrar</button>
                    <button onClick={handleReset}>Reset</button>
                </div></h2>
                <div>
                    {loading && <div>Cargando información...</div>}
                    {!loading && <>
                        <Clientes clientes={clientesResponse.clientes}
                            registros={registrosResponse.registros}
                            estados={estadosRegistroResponse.estados}
                            tipos_registro={tiposRegistroResponse.tipos}
                        />

                    </>}
                </div>
            </div>
            <Card title="Estados">
                {loading && <div>Loading...</div>}
                {!loading && <EstadosRegistro estados={estadosRegistroResponse.estados} registros={registrosResponse.registros} />}
            </Card>
            <Card title="Tipos de Cliente">
                {loading && <div>Loading...</div>}
                {!loading && <TiposCliente clientes={clientesResponse.clientes} registros={registrosResponse.registros} tiposCliente={tiposClienteResponse.tipos} />}
            </Card>
            <Card title="Tipos de registro">
                {loading && <div>Loading...</div>}
                {!loading && <TiposRegistro tipos={tiposRegistroResponse.tipos} registros={registrosResponse.registros} />}
            </Card>
            <Card title="Derechos">
                {loading && <div>Loading...</div>}
                {!loading && <Derechos derechos={derechosResponse.derechos} registros={registrosResponse.registros} />}
            </Card>
            <Card title="Tiempo de uso">
                {loading && <div>Loading...</div>}
                {!loading && <TiempoDeUso tiempoDeUso={tiempoDeUsoResponse.tiempos} registros={registrosResponse.registros} />}
            </Card>
            <Card title="Tipos de documento">
                {loading && <div>Loading...</div>}
                {!loading && <TiposDocumento tiposDocumento={tiposDocumentoResponse.tipos} registros={registrosResponse.registros} />}
            </Card>
        </div>
    );
};

const Card = ({ title, children, style = {} }) => {
    return (
        <div className="Card" style={style}>
            <h2>{title}</h2>
            <div>
                {children}
            </div>
        </div>
    );
};

const Clientes = ({ clientes, registros, estados, tipos_registro }) => {

    return (<>
        <ClientesValores clientes={clientes} registros={registros} estados={estados} />
        <ClientesTipoRegistro clientes={clientes} registros={registros} tipos={tipos_registro} />
        {/* <ClientesDerechos clientes={clientes} registros={registros} derechos={derechos} /> */}
    </>);
};

const ClientesValores = ({ clientes, registros, estados }) => {
    let colors = chartColors;
    let clientes_series = [];
    let max = 0;
    let sumTotal = 0;

    for (const cliente of clientes) {
        let fila = {
            name: `${cliente.nombre}`,
            estados: estados.map((estado) => {
                return {
                    name: estado.nombre,
                    value: 0
                };
            }),
            total: 0
        }

        for (let registro of registros) {
            if (registro.cliente.id === cliente.id) {
                fila.estados.forEach((estado) => {
                    if (estado.name === registro.estado.nombre) {
                        estado.value += registro.valor;
                    }
                });
            }
        }

        let total = fila.estados.reduce((acc, estado) => acc + estado.value, 0);
        if (total > max) {
            max = total;
        }
        if (total > 0) {
            fila.total = total;
            sumTotal+=total;
            clientes_series.push(fila);
        }
    }
    return (
        <>
            <table className="Dashboard-detalle-clientes">
                <thead>
                    <tr>
                        <th>Clientes</th>
                        <th>Estados</th>
                        <th>Valores</th>
                    </tr>
                </thead>
                <tbody>
                    {clientes_series.map((cliente, i) => (
                        <tr key={i}>
                            <td>{cliente.name}</td>
                            <td>
                                <div>
                                    {estados.map((estado, j) => {
                                        let valor = cliente.estados.find((e) => e.name === estado.nombre).value;
                                        if (valor > 0) {
                                            return (
                                                <div key={j} style={{
                                                    width: `${(valor / max) * 100}%`,
                                                    backgroundColor: colors[j],
                                                    display: "inline-block",
                                                    height: 20
                                                }}></div>
                                            );
                                        }
                                        return (<></>);
                                    })}
                                </div>
                            </td>
                            <td>
                                $ {cliente.total.toLocaleString()}
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={2} style={{textAlign:'right'}}>Total</td>
                        <td><b>$ {sumTotal.toLocaleString()}</b></td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <div className="Leyenda">
                                {estados.map((estado, j) => (
                                    <div>
                                        <div key={j} style={{
                                            width: `20px`,
                                            height: '20px',
                                            backgroundColor: colors[j],
                                            display: "inline-block",
                                        }}></div>&nbsp;
                                        {estado.nombre}
                                    </div>
                                ))}
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </>
    );
};

const ClientesTipoRegistro = ({ clientes, registros, tipos }) => {
    let colors = chartColors;
    let clientes_series = [];
    let max = 0;

    for (const cliente of clientes) {
        let fila = {
            name: `${cliente.nombre}`,
            tipos: tipos.map((tipo) => {
                return {
                    name: tipo.nombre,
                    value: 0
                };
            }),
            total: 0
        }

        for (let registro of registros) {
            if (registro.cliente.id === cliente.id) {
                fila.tipos.forEach((tipo) => {
                    if (tipo.name === registro.tipo.nombre) {
                        tipo.value += 1;
                    }
                });
            }
        }

        let total = fila.tipos.reduce((acc, tipo) => acc + tipo.value, 0);
        if (total > max) {
            max = total;
        }
        if (total > 0) {
            fila.total = total;
            clientes_series.push(fila);
        }
    }

    return (
        <>
            <table className="Dashboard-detalle-clientes">
                <thead>
                    <tr>
                        <th>Clientes</th>
                        <th>Tipos de registro</th>
                        <th>Proyectos</th>
                    </tr>
                </thead>
                <tbody>
                    {clientes_series.map((cliente, i) => (
                        <tr key={i}>
                            <td>{cliente.name}</td>
                            <td>
                                <div>
                                    {tipos.map((tipo, j) => {
                                        let valor = cliente.tipos.find((e) => e.name === tipo.nombre).value;
                                        if (valor > 0) {
                                            return (
                                                <div key={j} style={{
                                                    width: `${(valor / max) * 100}%`,
                                                    backgroundColor: colors[j],
                                                    display: "inline-block",
                                                    height: 20
                                                }}></div>
                                            );
                                        }
                                        return (<></>);
                                    })}
                                </div>
                            </td>
                            <td>
                                {cliente.total.toLocaleString()}
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={3}>
                            <div className="Leyenda">
                                {tipos.map((tipo, j) => (
                                    <div>
                                        <div key={j} style={{
                                            width: `20px`,
                                            height: '20px',
                                            backgroundColor: colors[j],
                                            display: "inline-block",
                                        }}></div>&nbsp;
                                        {tipo.nombre}
                                    </div>
                                ))}
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </>
    );
};

const TiempoDeUso = ({ tiempoDeUso, registros }) => {
    let tiempoDeUso_series = [];
    for (let i = 0; i < tiempoDeUso.length; i++) {
        let valor = 0;
        let cantidad = 0;
        for (let j = 0; j < registros.length; j++) {
            if (registros[j].tiempo_de_uso.id === tiempoDeUso[i].id) {
                valor += registros[j].valor;
                cantidad++;
            }
        }
        valor > 0 && tiempoDeUso_series.push({ name: tiempoDeUso[i].nombre, value: valor, cant: cantidad });
    }

    return (
        <>
            <PieChart serie={tiempoDeUso_series} radio={radio} hideCategories={true} />
            <TableCantidadValor serie={tiempoDeUso_series} />
        </>
    );
};

const TiposDocumento = ({ tiposDocumento, registros }) => {
    let tiposDocumento_series = [];
    for (let i = 0; i < tiposDocumento.length; i++) {
        let valor = 0;
        let cantidad = 0;
        for (let j = 0; j < registros.length; j++) {
            if (registros[j].tipo_documento.id === tiposDocumento[i].id) {
                valor += registros[j].valor;
                cantidad++;
            }
        }
        valor > 0 && tiposDocumento_series.push({ name: tiposDocumento[i].nombre, value: valor, cant: cantidad });
    }

    return (
        <>
            <PieChart serie={tiposDocumento_series} radio={radio} hideCategories={true} />
            <TableCantidadValor serie={tiposDocumento_series} />
        </>
    );
};

const TiposCliente = ({ tiposCliente, clientes, registros }) => {
    let tiposCliente_series = [];
    for (let i = 0; i < tiposCliente.length; i++) {
        let valor = 0;
        let cantidad = 0;
        for (let j = 0; j < clientes.length; j++) {
            if (clientes[j].tipo_cliente.id === tiposCliente[i].id) {
                for (let k = 0; k < registros.length; k++) {
                    if (registros[k].cliente.id === clientes[j].id) {
                        valor += registros[k].valor;
                        cantidad++;
                    }
                }
            }
        }
        valor > 0 && tiposCliente_series.push({ name: tiposCliente[i].nombre, value: valor, cant: cantidad });
    }

    return (
        <>
            <PieChart serie={tiposCliente_series} radio={radio} hideCategories={true} />
            <TableCantidadValor serie={tiposCliente_series} />
        </>
    );
};


const Derechos = ({ derechos, registros }) => {
    let derechos_series = [];
    for (let i = 0; i < derechos.length; i++) {
        let valor = 0;
        let cantidad = 0;
        for (let j = 0; j < registros.length; j++) {
            if (registros[j].derechos.id === derechos[i].id) {
                valor += registros[j].valor;
                cantidad++;
            }
        }
        valor > 0 && derechos_series.push({ name: derechos[i].nombre, value: valor, cant: cantidad });
    }

    return (
        <>
            <PieChart serie={derechos_series} radio={radio} hideCategories={true} />
            <TableCantidadValor serie={derechos_series} />
        </>
    );
};

const EstadosRegistro = ({ estados, registros }) => {

    let estados_series = [];
    for (let i = 0; i < estados.length; i++) {
        // let count = registros.filter((registro) => registro.estado.id === estados[i].id).length;
        // count>0 && estados_series.push({ name: estados[i].nombre, value: count });
        let valor = 0;
        let cantidad = 0;
        for (let j = 0; j < registros.length; j++) {
            if (registros[j].estado.id === estados[i].id) {
                valor += registros[j].valor;
                cantidad++;
            }
        }
        estados_series.push({ name: estados[i].nombre, value: valor, cant: cantidad });
    }

    return (
        <>
            <PieChart serie={estados_series} radio={radio} hideCategories={true}/>
            <TableCantidadValor serie={estados_series} currency />
        </>
    );
};

const TiposRegistro = ({ tipos, registros }) => {
    let serie = [];
    for (let i = 0; i < tipos.length; i++) {
        let valor = 0;
        let cantidad = 0;

        for (let j = 0; j < registros.length; j++) {
            if (registros[j].tipo.id === tipos[i].id) {
                valor += registros[j].valor;
                cantidad++;
            }
        }
        valor > 0 && serie.push({ name: tipos[i].nombre, value: valor, cant: cantidad });
    }

    return (
        <>
            <PieChart serie={serie} radio={radio} hideCategories={true} />
            <TableCantidadValor serie={serie} />
        </>
    );
};

const TableCantidadValor = ({ serie, currency = false }) => {
    return (
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th>Cantidad</th>
                    <th>Valor</th>
                </tr>
            </thead>
            <tbody>
                {serie.map((elem, i) => (
                    <tr key={i}>
                        <td><i style={{width:'12px',height:'12px',borderRadius:'6px',display:'inline-block',background:chartColors[i]}}></i> {elem.name}</td>
                        <td>{elem.cant.toLocaleString()}</td>
                        <td className="currency">{elem.value.toLocaleString()}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default Dashboard;
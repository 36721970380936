import { useFetch } from "../hooks/useFetch";
import "../styles/views/CastingView.css";
import { serviceHttpRequest } from "../utils/serviceFetch.js";
import CastingListComponent from "../components/CastingListComponent.jsx";

const CastingView = ({ setActivity }) => {
    const setParticipar = (id, file, setUploading, setUploadProgress, setAudicion) => {
        let blob = new Blob([file], { type: file.type });
        setUploading(true);
        serviceHttpRequest(`audicionar/${id}`, 
            { method: "POST", body: blob }, 
            uploadedEvent => {
                setUploadProgress(1);
            },
            uploadprogressEvent => {
                setUploadProgress(uploadprogressEvent.loaded / uploadprogressEvent.total);
            }
        ).then(response => {
            setUploading(false);
            console.info(response);
            setAudicion(`${response.audicion}?${Math.random()}`);
        });
    }
    const { loading, data, error } = useFetch("casting");
    const { loading: loadingLocutor, data: dataLocutor, error: errorLocutor } = useFetch("get_perfil");
 
    return (
        <div className="AudiosView">
            {loadingLocutor && <p>Cargando Datos...</p>}
            {loading && <p>Cargando...</p>}
            {error && <p>Error: {error}</p>}
            {errorLocutor && <p>Error: {errorLocutor}</p>}
            
            {data && <>
                <CastingListComponent collection={data.castings} dataLocutor={dataLocutor} setParticipar={setParticipar} />
            </>}

        </div>
    );
};




export default CastingView;
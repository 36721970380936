"use client";
import React, { useEffect, useState } from "react";
import { serviceFetch } from "../utils/serviceFetch";

/**
 * InputLiveUpdate component.
 * 
 * This component is responsible for rendering an input field that updates the server when the user leaves the field.
 * 
 * @param {Object} props The properties of the component.
 * @param {string} props.type The type of the input field. Default is "text".
 * @param {string} props.value The value of the input field. Default is "".
 * @param {Object} props.style The style of the input field. Default is {}.
 * @param {string} props.url The URL to update the server. Default is null.
 * @param {function} props.onUpdate The function to call when the value is updated. Default is null.
 * @param {string} props.placeholder The placeholder of the input field. Default is "".
 * @returns {JSX.Element} The rendered InputLiveUpdate component.
 */
export const InputLiveUpdate = ({ type = "text", value = null, style, url = null, onUpdate = null, placeholder = "", inputRef=null }) => {
    if (value === null) value = "";
    const [localValue, setLocalValue] = useState(value);
    const [prev, setPrev] = useState(value);

    useEffect(() => {
        setLocalValue(value);
        setPrev(value);
    }, [value]);

    const handleOnBlur = (e) => {
        if (e.target.value === prev) return;
        if (url === null) {
            setLocalValue(e.target.value);
            if (onUpdate) {
                onUpdate(e.target.value);
            }
            return;
        }
        e.target.disabled = true;
        let formData = new FormData();
        formData.append("valor", e.target.value);
        serviceFetch(url, "POST", formData)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Error en la respuesta del servidor");
                }
                return response.json();
            })
            .then(data => {
                // console.log("Data: ", data.valor);
                setPrev(data.valor);
                if (onUpdate) onUpdate(data.valor);
            })
            .catch(error => {
                e.target.value = prev;
                console.log(error)
            })
            .finally(() => {
                e.target.disabled = false;
            });
    };

    if (type === "text")
        return (
            <textarea ref={inputRef} placeholder={placeholder} type={type} value={localValue} style={style} onChange={(e) => setLocalValue(e.target.value)} onBlur={handleOnBlur}></textarea>
        );
    else
        return (
            <input ref={inputRef} placeholder={placeholder} type={type} value={localValue} style={style} onChange={(e) => setLocalValue(e.target.value)} onBlur={handleOnBlur} />
        );
}

export const SelectLiveUpdate = ({ value = null, options, style, url = null, onUpdate = null }) => {
    const name = `select_${Number(Math.random() * 1000).toFixed(0)}`
    if (value === null) value = "";
    const [localValue, setLocalValue] = useState(value);
    const [prev, setPrev] = useState(value);

    useEffect(() => {
        setLocalValue(value);
        setPrev(value);
    }, [value]);

    const handleOnBlur = (e) => {
        if (e.target.value === prev) return;

        if (url === null) {
            setLocalValue(e.target.value);
            if (onUpdate) {
                onUpdate(e.target.value);
            }
            return;
        }

        e.target.disabled = true;

        let formData = new FormData();
        formData.append("valor", e.target.value);
        serviceFetch(url, "POST", formData)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Error en la respuesta del servidor");
                }
                return response.json();
            })
            .then(data => {
                console.log("Data: ", data.valor);
                setPrev(data.valor);
                if (onUpdate) onUpdate(data.valor);
            })
            .catch(error => {
                e.target.value = prev;
                console.log(error)
            })
            .finally(() => {
                e.target.disabled = false;
            });
    };

    return (
        <select value={localValue} style={style} onChange={(e) => setLocalValue(e.target.value)} onBlur={handleOnBlur}>
            <option value="">-</option>
            {options.map((opt) => (
                <option key={`${name}_${opt.id}`} value={opt.id}>{opt.nombre}</option>
            ))}
        </select>
    );
};

export const ChoicesLiveUpdate = ({ value = null, options, style, url = null, onUpdate = null }) => {
    const name = `choice_${Number(Math.random() * 1000).toFixed(0)}`

    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    const handleChange = (e) => {
        if (e.target.value === localValue) return;
        if (url === null) {
            setLocalValue(e.target.value);
            if (onUpdate) {
                onUpdate(e.target.value);
            }
            return;
        }
        let formData = new FormData();
        formData.append("valor", e.target.value);

        serviceFetch(url, "POST", formData)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Error en la respuesta del servidor");
                }
                return response.json();
            })
            .then(data => {
                setLocalValue(data.valor);
                if (onUpdate) onUpdate(data.valor);
            })
            .catch(error => {
                console.log(error)
            });
    };

    return (
        <div key={`${name}_`} className="ChoicesBox">
            {options.map((opt) => (<>
                <input key={`${name}_${opt.id}_input`} id={`${name}_${opt.id}`} type="radio" name={name} value={opt.id} checked={opt.id === localValue} onChange={handleChange} />
                <label key={`${name}_${opt.id}_label`} for={`${name}_${opt.id}`} className={opt.id === localValue ? "checked" : ""}>{opt.nombre}</label>
            </>))}
        </div>
    );
};

export const CheckboxLiveUpdate = ({ value = null, object: opt, style, url = null, onUpdate = null }) => {
    const name = `checkbox_${Number(Math.random() * 1000).toFixed(0)}`

    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    const handleChange = (e) => {
        if (e.target.checked === localValue) return;
        if (url === null) {
            setLocalValue(e.target.checked);
            if (onUpdate) {
                onUpdate(e.target.checked);
            }
            return;
        }
        let formData = new FormData();
        formData.append("valor", e.target.checked ? true : false);

        serviceFetch(url, "POST", formData)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Error en la respuesta del servidor");
                }
                return response.json();
            })
            .then(data => {
                setLocalValue(data.valor);
                if (onUpdate) onUpdate(data.valor);
            })
            .catch(error => {
                console.log(error)
            });
    };

    return (
        <div className="ChoicesBox">
            <input id={`${name}_${opt.id}`} type="checkbox" name={name} value={opt.id} checked={localValue} onChange={handleChange} />
            <label for={`${name}_${opt.id}`} className={localValue ? "checked" : ""}>{opt.nombre}</label>
        </div>
    );
};
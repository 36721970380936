import React, { useState } from "react";
import { useFetch } from "../hooks/useFetch";
import AudiosViewRecordContainer from "../containers/AudiosViewRecordContainer.jsx";
import "../styles/views/AudiosView.css";
import ModalContainer from "../containers/ModalContainer.jsx";
import AudioListComponent, { AudioComponent } from "../components/AudioListComponent.jsx";
import { RecordFillIcon } from "../components/BootstrapIcons.jsx";

const AudiosView = ({ setActivity }) => {
    const [main, setMain] = useState("audios");
    const [reload, setReload] = useState(0);

    const { loading, data, error } = useFetch("onerecord", {}, [main, reload]);
    const { loading: loadingLocutor, data: dataLocutor, error: errorLocutor } = useFetch("get_perfil");
    const [showRecordModal, setShowRecordModal] = useState(false);

    const [audioPath, setAudioPath] = useState(null);

    const [onerecord, setOnerecord] = useState(null);
 
    return (
        <div className="AudiosView">
            <div className="AudiosView_header">

                <h1>Graba lo que quieras</h1>
                <div>En esta sección podrás grabar demos libremente. Podrás grabarlos con los audios que tú quieras, o simplemente podrás hacer uso de tu voz en plenitud.</div>
                <button className="AudiosView_new" onClick={()=>setShowRecordModal(true)}>
                    <RecordFillIcon size="32" />&nbsp;Graba tu demo!
                </button>
            </div>
            {loadingLocutor && <p>Cargando Datos...</p>}
            {loading && <p>Cargando...</p>}
            {error && <p>Error: {error}</p>}
            {errorLocutor && <p>Error: {errorLocutor}</p>}
            {main === "audios" && data && <>
                <AudioListComponent collection={data.onerecord} onerecord={onerecord} setOnerecord={setOnerecord} />
                {(onerecord && dataLocutor) && 
                    <AudioComponent onerecord={onerecord} locutor={dataLocutor} setOnerecord={setOnerecord} reload={reload} setReload={setReload} />
                }
            </>}
            {main === "audios" && showRecordModal &&
                <ModalContainer display={showRecordModal} setDisplay={setShowRecordModal} >
                    <AudiosViewRecordContainer
                        tipo={1}
                        edad={1}
                        emocion={1}
                        setMain={setMain}
                        setAudioPath={setAudioPath}
                    />
                </ModalContainer>} 
            {main === "justsaved" && <div className="JustRecorded">
                <img src="https://godemos.cl/static/desktop/assets/img/logo.webp" alt="logo" height={128}/>
                <p>¡Felicitaciones! tu audio ha sido guardado con éxito</p>
                <p><a href={audioPath} rel="noreferrer" target="_blank">haz click aquí para descargar tu audio.</a></p>
                <button onClick={e =>{
                    setMain("audios");
                    setShowRecordModal(false);
                }}>Volver a tus Audios</button>
            </div>}
        </div>
    );
};



export default AudiosView;
import React, { useState } from "react";
import { useFetch } from "../hooks/useFetch";
import { InputLiveUpdate, SelectLiveUpdate } from "../components/LiveUpdateFields";
import PieChart from "../components/PieChart";
import { DashSquareIcon, DownloadIcon, EnvelopeAtIcon, FilterIcon, PlusSquareIcon } from "../components/BootstrapIcons";
import { serviceFetch } from "../utils/serviceFetch";


const ProjectsContainer = ({
    estadoResponse,
    tiposResponse,
    derechosResponse,
    tiempoDeUsoResponse,
    tiposDocumentoResponse }) => {

    const filterFormId = "filter_crm_form";

    return (
        <div className="Projects">
            <Container formId={filterFormId}
                estadoResponse={estadoResponse}
                tiposResponse={tiposResponse}
                derechosResponse={derechosResponse}
                tiempoDeUsoResponse={tiempoDeUsoResponse}
                tiposDocumentoResponse={tiposDocumentoResponse}
            />
        </div>
    );
};

const Container = ({ formId,
    estadoResponse,
    tiposResponse,
    derechosResponse,
    tiempoDeUsoResponse,
    tiposDocumentoResponse
}) => {

    const { data: clientesResponse, loading: clientesLoading } = useFetch("get_clientes");

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    let loading = clientesLoading;

    return (
        <>
            <FilterIcon onClick={() => {
                let filters = document.querySelector(".Filters");
                filters.style.display = filters.style.display === "none" ? "block" : "none";
            }} />
            <div className="Filters slideIn">
                <h1> Filtros</h1>
                {loading && <p>Cargando datos...</p>}
                {!loading &&
                    <form id={formId} onSubmit={handleSubmit}>
                        <label htmlFor="filter_crm_tipo">Tipo</label>
                        <select name="tipo" id="filter_crm_tipo">
                            <option value="">Todos</option>
                            {tiposResponse.tipos.map((t) => <option key={t.id} value={t.id}>{t.nombre}</option>)}
                        </select>
                        <label htmlFor="filter_crm_estado">Estado</label>
                        <select name="estado" id="filter_crm_estado">
                            <option value="">Todos</option>
                            {estadoResponse.estados.map((e) => <option key={e.id} value={e.id}>{e.nombre}</option>)}
                        </select>
                        <label htmlFor="filter_crm_derechos">Derechos</label>
                        <select name="derechos" id="filter_crm_derechos">
                            <option value="">Todos</option>
                            {derechosResponse.derechos.map((d) => <option key={d.id} value={d.id}>{d.nombre}</option>)}
                        </select>
                        <label htmlFor="filter_crm_tiempo_de_uso">Tiempo de uso</label>
                        <select name="tiempo_de_uso" id="filter_crm_tiempo_de_uso">
                            <option value="">Todos</option>
                            {tiempoDeUsoResponse.tiempos.map((t) => <option key={t.id} value={t.id}>{t.nombre}</option>)}
                        </select>
                        <label htmlFor="filter_crm_fecha_grabacion">Fecha de grabación</label>
                        <input type="month" name="fecha_grabacion" id="filter_crm_fecha_grabacion" />
                        <label htmlFor="filter_crm_fecha_facturacion">Fecha de facturación</label>
                        <input type="month" name="fecha_facturacion" id="filter_crm_fecha_facturacion" />
                        <label htmlFor="filter_crm_fecha_cobro">Fecha de cobro</label>
                        <input type="month" name="fecha_cobro" id="filter_crm_fecha_cobro" />
                        <label htmlFor="filter_crm_fecha_pago">Fecha de pago</label>
                        <input type="month" name="fecha_pago" id="filter_crm_fecha_pago" />
                        <label htmlFor="filter_crm_tipo_documento">Tipo de documento</label>
                        <select name="tipo_documento" id="filter_crm_tipo_documento">
                            <option value="">Todos</option>
                            {tiposDocumentoResponse.tipos.map((t) => <option key={t.id} value={t.id}>{t.nombre}</option>)}
                        </select>
                        <label htmlFor="filter_crm_valor">Valor</label>
                        <input type="number" name="valor" id="filter_crm_valor" />
                        <label htmlFor="filter_crm_numero_documento">Número de documento</label>
                        <input type="text" name="numero_documento" id="filter_crm_numero_documento" />
                        <label htmlFor="filter_crm_cliente">Cliente</label>
                        <select name="cliente" id="filter_crm_cliente">
                            <option value="">Todos</option>
                            {clientesResponse.clientes.map((c) => <option key={c.id} value={c.id}>{c.nombre}</option>)}
                        </select>
                        <label htmlFor="filter_crm_contacto">Contacto</label>
                        <input type="text" name="contacto" id="filter_crm_contacto" />
                        <label htmlFor="filter_crm_comentarios">Comentarios</label>
                        <input type="text" name="comentarios" id="filter_crm_comentarios" />
                        <div className="buttons">
                            <button type="submit">Filtrar</button>
                            <button type="reset">Limpiar</button>
                        </div>
                    </form>
                }
            </div>
            {!loading && <Results formId={formId} filters={{
                clientes: clientesResponse.clientes,
                estados: estadoResponse.estados,
                tipos: tiposResponse.tipos,
                derechos: derechosResponse.derechos,
                tiempos: tiempoDeUsoResponse.tiempos,
                tipos_documentos: tiposDocumentoResponse.tipos,
            }} />}
        </>
    );
}

const Results = ({ formId, filters }) => {
    const [path, setPath] = useState("get_registros_crm/id/desc");

    const filterForm = document.getElementById(formId);

    const [loadIterator, setLoadIterator] = useState(0);

    const { data: registrosResponse, loading: registrosLoading } = useFetch(path, {
        method: "POST",
        body: filterForm ? new FormData(filterForm) : null,
    }, [loadIterator]);

    if (filterForm) {
        filterForm.onsubmit = (e) => {
            e.preventDefault();
            console.log("Set onSubmit");
            setLoadIterator(loadIterator + 1);
        };
    }

    const exportButton = React.createRef();

    const handleExportar = (e) => {
        e.preventDefault();
        exportButton.current.style.visibility = "hidden";
        serviceFetch("registros_crm/export").then((data) => {
            if (data.ok) {
                return data.blob();
            }
        }).then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = "registros_crm.xlsx";
            a.click();
            exportButton.current.style.visibility = "visible";
        });
    };

    return (
        <div className="Results" >
            <div ref={exportButton} className="ProjectsContainer__Export">
                <div onClick={handleExportar}><DownloadIcon size="16"/> exportar</div>
            </div>
            {registrosLoading && <p>Cargando datos...</p>}
            {!registrosLoading && <>
                <Charts registros={registrosResponse.registros} formId={formId} />
                <Data registrosReponse={registrosResponse} filters={filters} setRequestURL={setPath} />
            </>}
        </div>
    );
};

const Charts = ({ registros, formId }) => {
    let serie_estados = [];
    let serie_derechos = [];
    let serie_tipos = [];
    let serie_tiempos = [];
    let serie_tipos_documentos = [];
    let serie_clientes = [];

    let form = document.getElementById(formId);

    registros.forEach((r) => {
        let estado = serie_estados.find((s) => s.name === r.estado.nombre);
        if (estado) {
            estado.value++;
        } else {
            if (r.estado.id)
                serie_estados.push({
                    name: r.estado.nombre,
                    value: 1,
                    onClick: () => {
                        ;
                        let input = form.querySelector("select[name='estado']");
                        input.value = r.estado.id;
                        form.dispatchEvent(new Event("submit"));
                    }
                });
        }

        let derecho = serie_derechos.find((s) => s.name === r.derechos.nombre);
        if (derecho) {
            derecho.value++;
        } else {
            if (r.derechos.id)
                serie_derechos.push({
                    name: r.derechos.nombre,
                    value: 1,
                    onClick: () => {
                        ;
                        let input = form.querySelector("select[name='derechos']");
                        input.value = r.derechos.id;
                        form.dispatchEvent(new Event("submit"));
                    }
                });
        }

        let tipo = serie_tipos.find((s) => s.name === r.tipo.nombre);
        if (tipo) {
            tipo.value++;
        } else {
            if (r.tipo.id)
                serie_tipos.push({
                    name: r.tipo.nombre,
                    value: 1,
                    onClick: () => {
                        ;
                        let input = form.querySelector("select[name='tipo']");
                        input.value = r.tipo.id;
                        form.dispatchEvent(new Event("submit"));
                    }
                });
        }

        let tiempo = serie_tiempos.find((s) => s.name === r.tiempo_de_uso.nombre);
        if (tiempo) {
            tiempo.value++;
        } else {
            if (r.tiempo_de_uso.id)
                serie_tiempos.push({
                    name: r.tiempo_de_uso.nombre,
                    value: 1,
                    onClick: () => {
                        ;
                        let input = form.querySelector("select[name='tiempo_de_uso']");
                        input.value = r.tiempo_de_uso.id;
                        form.dispatchEvent(new Event("submit"));
                    }
                });
        }

        let tipo_documento = serie_tipos_documentos.find((s) => s.name === r.tipo_documento.nombre);
        if (tipo_documento) {
            tipo_documento.value++;
        } else {
            if (r.tipo_documento.id)
                serie_tipos_documentos.push({
                    name: r.tipo_documento.nombre,
                    value: 1,
                    onClick: () => {
                        let input = form.querySelector("select[name='tipo_documento']");
                        input.value = r.tipo_documento.id;
                        form.dispatchEvent(new Event("submit"));
                    }
                });
        }

        let cliente = serie_clientes.find((s) => s.name === r.cliente.nombre);
        if (cliente) {
            cliente.value++;
        } else {
            if (r.cliente.id)
                serie_clientes.push({
                    name: r.cliente.nombre,
                    value: 1,
                    onClick: () => {
                        ;
                        let input = form.querySelector("select[name='cliente']");
                        input.value = r.cliente.id;
                        form.dispatchEvent(new Event("submit"));
                    }
                });
        }
    });

    return (
        <div className="Charts" style={{ display: 'inline-flex' }}>
            <PieChart radio={75} serie={serie_estados} />
            <span style={{ width: '75px' }}></span>
            <PieChart radio={75} serie={serie_derechos} />
            <span style={{ width: '75px' }}></span>
            <PieChart radio={75} serie={serie_tipos} />
            <span style={{ width: '75px' }}></span>
            <PieChart radio={75} serie={serie_tiempos} />
            <span style={{ width: '75px' }}></span>
            <PieChart radio={75} serie={serie_tipos_documentos} />
            <span style={{ width: '75px' }}></span>
            {/* <PieChart radio={75} serie={serie_clientes} /> */}
        </div>
    );
};

const Data = ({ registrosReponse, filters, setRequestURL }) => {

    return (
        <div className="Data">
            <Pagination />
            <Table registros={registrosReponse.registros}
                estados={filters.estados}
                tipos={filters.tipos}
                derechos={filters.derechos}
                tiempos={filters.tiempos}
                tipos_documentos={filters.tipos_documentos}
                clientes={filters.clientes}
                setRequestURL={setRequestURL}
            />
        </div>
    );
};

const Table = ({ registros, estados, tipos, derechos, tiempos, tipos_documentos, clientes, setRequestURL }) => {
    const tableWidth = {
        descripcion: "300px",
        estado: "150px",
        tipo: "150px",
        derechos: "150px",
        tiempo_de_uso: "150px",
        fecha_grabacion: "150px",
        fecha_facturacion: "150px",
        fecha_cobro: "150px",
        fecha_pago: "150px",
        tipo_documento: "140px",
        numero_documento: "130px",
        valor: "150px",
        cliente: "170px",
        contacto: "150px",
        comentarios: "250px"
    };

    const handleSort = (e) => {
        // const campo = e.target.getAttribute("data-campo");
        const orden = e.target.getAttribute("data-orden") || "asc";
        const newOrden = orden === "asc" ? "desc" : "asc";

        let thPosition = Array.from(e.target.closest("tr").children).indexOf(e.target);

        let tbody = e.target.closest("table").querySelector("tbody");
        let rows = Array.from(tbody.rows);

        rows.sort((a, b) => {
            // console.log(a,thPosition,`td:nth-child(${thPosition})`,a.querySelector(`td:nth-child(${thPosition})`));
            let aValue = a.querySelector(`td:nth-child(${thPosition + 1}) input, td:nth-child(${thPosition + 1}) select, td:nth-child(${thPosition + 1}) textarea `).value;
            let bValue = b.querySelector(`td:nth-child(${thPosition + 1}) input, td:nth-child(${thPosition + 1}) select, td:nth-child(${thPosition + 1}) textarea`).value;

            if (orden === "asc") {
                return aValue > bValue ? 1 : -1;
            } else {
                return aValue < bValue ? 1 : -1;
            }
        });

        // if (newOrden === "desc") {
        //     rows.reverse();
        // }

        tbody.innerHTML = "";
        rows.forEach((r) => tbody.appendChild(r));

        e.target.setAttribute("data-orden", newOrden);
    };

    const [refresh, setRefresh] = useState(0);

    const addRegistro = () => {
        serviceFetch("add_registro_crm", "PUT").then((data) => {
            if (data.ok) {
                return data.json();
            }
            data.json().then((json) => {
                if(json.success === 0){
                    alert(json.message);
                }
            });

        }).then((data) => {
            if (data) {
                // registros.push(data.registro) as first element
                registros.unshift(data.registro);
                setRefresh(refresh + 1);
            }
        });
    };

    const deleteRegistro = (id) => {
        if (!window.confirm("¿Está seguro de eliminar este registro?")) {
            return;
        }
        serviceFetch(`delete_registro_crm/${id}`, "DELETE").then((data) => {
            if (data.ok) {
                let index = registros.findIndex((r) => r.id === id);
                registros.splice(index, 1);
                setRefresh(refresh + 1);
            }
        });
    };

    return (
        <table key={`registros_${refresh}`}>
            <thead>
                <tr>
                    <th><PlusSquareIcon onClick={addRegistro} /></th>
                    <th style={{ width: tableWidth.descripcion }} data-campo="descripcion" onClick={handleSort}>Descripción</th>
                    <th style={{ width: tableWidth.estado }} data-campo="estado" onClick={handleSort}>Estado</th>
                    <th style={{ width: tableWidth.tipo }} data-campo="tipo" onClick={handleSort}>Tipo</th>
                    <th style={{ width: tableWidth.derechos }} data-campo="derechos" onClick={handleSort}>Derechos</th>
                    <th style={{ width: tableWidth.tiempo_de_uso }} data-campo="tiempo_de_uso" onClick={handleSort}>Tiempo de uso</th>
                    <th style={{ width: tableWidth.fecha_grabacion }} data-campo="fecha_grabacion" onClick={handleSort}>Fecha de grabación</th>
                    <th style={{ width: tableWidth.fecha_facturacion }} data-campo="fecha_facturacion" onClick={handleSort}>Fecha de facturación</th>
                    <th style={{ width: tableWidth.fecha_cobro }} data-campo="fecha_cobro" onClick={handleSort}>Fecha de cobro</th>
                    <th style={{ width: tableWidth.fecha_pago }} data-campo="fecha_pago" onClick={handleSort}>Fecha de pago</th>
                    <th style={{ width: tableWidth.tipo_documento }} data-campo="tipo_documento" onClick={handleSort}>Tipo de documento</th>
                    <th style={{ width: tableWidth.numero_documento }} data-campo="numero_documento" onClick={handleSort}>Número de documento</th>
                    <th style={{ width: tableWidth.valor }} data-campo="valor" onClick={handleSort}>Valor</th>
                    <th style={{ width: tableWidth.cliente }} data-campo="cliente" onClick={handleSort}>Cliente</th>
                    <th style={{ width: tableWidth.cliente }} data-campo="cliente" onClick={handleSort}>Contacto Cliente</th>
                    <th style={{ width: tableWidth.contacto }} data-campo="contacto" onClick={handleSort}>Contacto 2</th>
                    <th style={{ width: tableWidth.comentarios }} data-campo="comentarios" onClick={handleSort}>Comentarios</th>
                </tr>
            </thead>
            <tbody>
                {registros.map((r) => {
                    const emailRef = React.createRef();

                    return (
                    <tr key={r.id}>
                        <td>
                            <DashSquareIcon onClick={() => deleteRegistro(r.id)} />
                        </td>
                        {/* Descripción */}
                        <td style={{ width: tableWidth.descripcion }}>
                            <InputLiveUpdate
                                value={r.descripcion}
                                url={`edit_registro_crm/descripcion/${r.id}`}
                                onUpdate={(v) => { r.descripcion = v; }}
                                style={{
                                    minWidth: tableWidth.descripcion,
                                    width: tableWidth.descripcion
                                }}
                            />
                        </td>

                        {/* Estado del Registro */}
                        <td style={{ width: tableWidth.estado }}>
                            <SelectLiveUpdate
                                value={r.estado.id}
                                options={estados}
                                url={`edit_registro_crm/estado/${r.id}`}
                                onUpdate={(v) => { r.estado.id = v; }}
                                style={{
                                    minWidth: tableWidth.estado,
                                    width: tableWidth.estado
                                }}
                            />
                        </td>

                        {/* Tipo del Registro */}
                        <td style={{ width: tableWidth.tipo }}>
                            <SelectLiveUpdate
                                value={r.tipo.id}
                                options={tipos}
                                url={`edit_registro_crm/tipo/${r.id}`}
                                onUpdate={(v) => { r.tipo.id = v; }}
                                style={{
                                    minWidth: tableWidth.tipo,
                                    width: tableWidth.tipo
                                }}

                            />
                        </td>

                        {/* Derechos */}
                        <td style={{ width: tableWidth.derechos }}>
                            <SelectLiveUpdate
                                value={r.derechos.id}
                                options={derechos}
                                url={`edit_registro_crm/derechos/${r.id}`}
                                onUpdate={(v) => { r.derechos.id = v; }}
                                style={{
                                    minWidth: tableWidth.derechos,
                                    width: tableWidth.derechos
                                }}
                            />
                        </td>

                        {/* Tipo de Uso */}
                        <td style={{ width: tableWidth.tiempo_de_uso }}>
                            <SelectLiveUpdate
                                value={r.tiempo_de_uso.id}
                                options={tiempos}
                                url={`edit_registro_crm/tiempo_de_uso/${r.id}`}
                                onUpdate={(v) => { r.tiempo_de_uso.id = v; }}
                                style={{
                                    minWidth: tableWidth.tiempo_de_uso,
                                    width: tableWidth.tiempo_de_uso
                                }}
                            />
                        </td>

                        {/* Fecha de Grabación */}
                        <td style={{ width: tableWidth.fecha_grabacion }}>
                            <InputLiveUpdate
                                value={r.fecha_grabacion}
                                type="date"
                                url={`edit_registro_crm/fecha_grabacion/${r.id}`}
                                onUpdate={(v) => { r.fecha_grabacion = v; }}
                                style={{
                                    minWidth: tableWidth.fecha_grabacion,
                                    width: tableWidth.fecha_grabacion
                                }}
                            />
                        </td>
                        {/* Fecha de Facturación */}
                        <td style={{ width: tableWidth.fecha_facturacion }}>
                            <InputLiveUpdate
                                value={r.fecha_facturacion}
                                type="date"
                                url={`edit_registro_crm/fecha_facturacion/${r.id}`}
                                onUpdate={(v) => { r.fecha_facturacion = v; }}
                                style={{
                                    minWidth: tableWidth.fecha_facturacion,
                                    width: tableWidth.fecha_facturacion
                                }}
                            />
                        </td>

                        {/* Fecha de Cobro */}
                        <td style={{ width: tableWidth.fecha_cobro }}>
                            <InputLiveUpdate
                                value={r.fecha_cobro}
                                type="date"
                                url={`edit_registro_crm/fecha_cobro/${r.id}`}
                                onUpdate={(v) => { r.fecha_cobro = v; }}
                                style={{
                                    minWidth: tableWidth.fecha_cobro,
                                    width: tableWidth.fecha_cobro
                                }}
                            />
                        </td>

                        {/* Fecha de Pago */}
                        <td style={{ width: tableWidth.fecha_pago }}>
                            <InputLiveUpdate
                                value={r.fecha_pago}
                                type="date"
                                url={`edit_registro_crm/fecha_pago/${r.id}`}
                                onUpdate={(v) => { r.fecha_pago = v; }}
                                style={{
                                    minWidth: tableWidth.fecha_pago,
                                    width: tableWidth.fecha_pago
                                }}
                            />
                        </td>

                        {/* Tipo de Documento */}
                        <td style={{ width: tableWidth.tipo_documento }}>
                            <SelectLiveUpdate
                                value={r.tipo_documento.id}
                                options={tipos_documentos}
                                url={`edit_registro_crm/tipo_documento/${r.id}`}
                                onUpdate={(v) => { r.tipo_documento.id = v; }}
                                style={{
                                    minWidth: tableWidth.tipo_documento,
                                    width: tableWidth.tipo_documento
                                }}
                            />
                        </td>

                        {/* Número de Documento */}
                        <td style={{ width: tableWidth.numero_documento }}>
                            <InputLiveUpdate
                                value={r.numero_documento}
                                url={`edit_registro_crm/numero_documento/${r.id}`}
                                onUpdate={(v) => { r.numero_documento = v; }}
                                style={{
                                    minWidth: tableWidth.numero_documento,
                                    width: tableWidth.numero_documento
                                }}
                            />
                        </td>

                        {/* Valor / Ganancia */}
                        <td style={{ width: tableWidth.valor }}>
                            <InputLiveUpdate
                                value={r.valor}
                                url={`edit_registro_crm/valor/${r.id}`}
                                onUpdate={(v) => { r.valor = v; }}
                                style={{
                                    minWidth: tableWidth.valor,
                                    width: tableWidth.valor
                                }}
                            />
                        </td>

                        {/* Cliente */}
                        <td style={{ width: tableWidth.cliente }}>
                            <SelectLiveUpdate
                                value={r.cliente.id}
                                options={clientes}
                                url={`edit_registro_crm/cliente/${r.id}`}
                                onUpdate={(v) => {
                                    r.cliente.id = v.id;
                                    r.contacto = v.email;
                                    emailRef.current.innerHTML = v.email;
                                }}
                                style={{
                                    minWidth: tableWidth.cliente,
                                    width: tableWidth.cliente
                                }}
                            />
                        </td>
                        <td>
                            
                            {r.cliente.email !== '-' && <a ref={emailRef} href={`mailto:${r.cliente.email}`} style={{padding:'10px',color:'#444',textDecoration:'none', textWrap:'nowrap'}}><EnvelopeAtIcon size="16" /> {r.cliente.email}</a>}
                        </td>
                        {/* Email de Contacto */}
                        <td style={{ width: tableWidth.contacto }}>
                            <InputLiveUpdate
                                value={r.contacto}
                                url={`edit_registro_crm/contacto/${r.id}`}
                                onUpdate={(v) => { r.contacto = v; }}
                                style={{
                                    minWidth: tableWidth.contacto,
                                    width: tableWidth.contacto
                                }}
                            />
                        </td>

                        {/* Comentarios */}
                        <td style={{ width: tableWidth.comentarios }}>
                            <InputLiveUpdate
                                value={r.comentarios}
                                url={`edit_registro_crm/comentarios/${r.id}`}
                                onUpdate={(v) => { r.comentarios = v; }}
                                style={{
                                    minWidth: tableWidth.comentarios,
                                    width: tableWidth.comentarios
                                }}
                            />
                        </td>
                    </tr>
                )})}
            </tbody>
        </table>
    );
};

const Pagination = () => {
    return (
        <div className="Pagination">
        </div>
    );
};

export default ProjectsContainer;
import React, { useEffect, useRef, useState } from "react";
import "../styles/AudiosViewRecordContainer.css";
// import { useFetch } from "../hooks/useFetch.jsx";
import { CheckboxLiveUpdate, ChoicesLiveUpdate } from "../components/LiveUpdateFields.jsx";
import AudioGoDemosComponent from "../components/AudioGoDemosComponent.jsx";
import { serviceFetch, serviceHttpRequest } from "../utils/serviceFetch.js";
import { ClipboardIcon, CloudArrowUp, MicIcon, PCICardIcon, StopwatchIcon } from "../components/BootstrapIcons.jsx";
// import AudioElementComponent from "./AudioElementComponent.jsx";

const AudiosViewRecordContainer = ({ tipo, edad, emocion, setMain, setAudioPath }) => {

    // eslint-disable-next-line
    // const { data, loading, error } = useFetch(`datatorecord/${tipo}/${edad}/${emocion}`);

    const [selectedDevice, setSelectedDevice] = useState(null);
    const [mediaStream, setMediaStream] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [recording, setRecording] = useState(false);
    const [echoCancelation, setEchoCancelation] = useState(false);
    const [noiseSuppression, setNoiseSuppression] = useState(false);
    const [micVolume, setMicVolume] = useState(0.7);

    const [audioBlob, setAudioBlob] = useState(null);

    const [duration, setDuration] = useState(60);
    const [silence, setSilence] = useState(true);

    const [audioMonitor, setAudioMonitor] = useState(null);
    const [monitor, setMonitor] = useState(false);

    const timeclockElement = useRef(null);

    const callbackLeftPanel = (audioElement) => {
        if (audioElement !== audioMonitor) {
            setAudioMonitor(audioElement);
            setDuration(audioElement.duration);
            setSilence(false);
        }
    };

    const handleDataAvailable = (event) => {
        console.debug("Data available", event.data);
        if (event.data.size > 0) {
            setAudioBlob(new Blob([].concat(event.data), { type: 'audio/mp3' }));
        }
    };

    const startRecording = (timer = 0) => {
        if (timer > 0) {
            timeclockElement.current.style.display = "";
            timeclockElement.current.textContent = timer;
            console.log("Timer: ", timer);
            setTimeout(() => {
                startRecording(--timer);
            }, 1000);
            return;
        }
        timeclockElement.current.style.display = "none";
        if (mediaStream) {
            console.debug("Start recording", audioMonitor);
            if (monitor && !audioMonitor.paused) {
                audioMonitor.pause();
                audioMonitor.currentTime = 0;
            }
            setAudioBlob(null);
            const recorder = new MediaRecorder(mediaStream);
            recorder.ondataavailable = handleDataAvailable;
            console.debug("Recorder setted", recorder);
            if (monitor) {
                audioMonitor.play();
            }
            recorder.start();
            setMediaRecorder(recorder);
            setRecording(true);
            setTimeout(() => {
                console.debug("Timeout reached", recorder.state);
                if (recorder.state === "recording")
                    // TODO: Encontrar una solución más elegante
                    //    al llamar directamente a stopRecording
                    //    no se estaba lanzando el evento 'dataavailable'
                    document.getElementById("CentralPanel_stopRecording").click();
            }, duration * 1000);
            console.debug("Timeout set", duration * 1000);
        }
    };

    const stopRecording = () => {
        console.info("Stop recording");
        if (mediaRecorder) {
            console.debug("Stop recording", mediaRecorder);
            if (monitor) {
                audioMonitor.pause();
            }
            mediaRecorder.stop();
            console.debug("Recorder stopped", mediaRecorder);

            // mediaRecorder.dispatchEvent(new Event('dataavailable'));

            setRecording(false);
        }
    };

    useEffect(() => {
        // if (mediaStream) {
        //     mediaStream.getTracks().forEach((track) => track.stop());
        // }

        if (selectedDevice === null) return;

        try {
            // const stream = await navigator.mediaDevices.getUserMedia(constraints);
            navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: selectedDevice ? { exact: selectedDevice } : undefined,
                    echoCancellation: echoCancelation,
                    sampleRate: 48000,
                    noiseSuppression: noiseSuppression,
                    autoGainControl: false,
                    // channelCount: 2,
                },
            })
                .then((stream) => {
                    const audioContext = new AudioContext();
                    const source = audioContext.createMediaStreamSource(stream);
                    const merger = audioContext.createChannelMerger(1);
                    const gainNode = audioContext.createGain();
                    source.connect(merger);
                    merger.connect(gainNode);

                    gainNode.gain.value = micVolume;

                    const modifiedStream = audioContext.createMediaStreamDestination();
                    merger.connect(modifiedStream);

                    setMediaStream(modifiedStream.stream);
                    console.info(modifiedStream.stream);
                })
                .catch((error) => {
                    console.error(error);
                    alert("No se pudo acceder al micrófono seleccionado. Por favor, selecciona otro micrófono.")
                });
        } catch (error) {
            console.error(error);
        }

        return () => {
            if (mediaStream) {
                mediaStream.getTracks().forEach((track) => track.stop());
            }
        };
        // eslint-disable-next-line
    }, [selectedDevice, echoCancelation, noiseSuppression, micVolume]);

    return (
        <div className="AudiosViewRecord">
            {<>
                <div ref={timeclockElement} className="TimerClock" style={{ display: "none" }}>5</div>
                <LeftPanel
                    recording={recording}
                    selectedDevice={selectedDevice}
                    setSelectedDevice={setSelectedDevice}
                    callback={callbackLeftPanel}
                    monitor={monitor}
                    setMonitor={setMonitor}
                    micVolume={micVolume}
                    setMicVolume={setMicVolume}
                    echoCancelation={echoCancelation}
                    setEchoCancelation={setEchoCancelation}
                    noiseSuppression={noiseSuppression}
                    setNoiseSuppression={setNoiseSuppression}
                />
                <CenterPanel
                    startRecording={startRecording}
                    stopRecording={stopRecording}
                    recording={recording}
                    duration={duration}
                />
                <RightPanel
                    audioBlob={audioBlob}
                    setMain={setMain}
                    silence={silence}
                    setAudioPath={setAudioPath}
                />
            </>
            }
        </div>
    );
};

const LeftPanel = ({ setSelectedDevice, selectedDevice, monitor, setMonitor, micVolume, setMicVolume, echoCancelation, setEchoCancelation, noiseSuppression, setNoiseSuppression, recording, callback }) => {
    const [loadingDevices, setLoadingDevices] = useState(true);
    const [devices, setDevices] = useState([]);
    const [display, setDisplay] = useState("block");
    const [audioPath, setAudioPath] = useState(null);
    const [audioElement, setAudioElement] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    // get available audio devices
    useEffect(() => {
        (async () => {
            try {
                // let auxMedia = await navigator.mediaDevices.getUserMedia({ audio: true });
                // if(!auxMedia) return console.error("No se pudo acceder a los dispositivos de audio");
                let allDevices = await navigator.mediaDevices.enumerateDevices();
                let auxDevices = [];
                allDevices.map((device) => {
                    if (device.kind !== "audioinput") return null;
                    if (device.deviceId === "default") return null;
                    if (device.deviceId === "communications") return null;
                    // console.debug(device);
                    auxDevices.push({
                        id: device.deviceId,
                        nombre: device.label
                    });
                    if (auxDevices.length === 1) setSelectedDevice(device.deviceId);
                    return null;
                });
                setDevices(auxDevices);
                setLoadingDevices(false);

            } catch (error) {
                console.error('Error loading audio devices:', error);
            }
        })();
        // eslint-disable-next-line
    }, []);

    // hide device selection when recording
    useEffect(() => {
        if (recording) {
            setDisplay("none");
        } else {
            setDisplay("block");
        }
    }, [recording]);

    const callbackAudioElement = (audioElement) => {
        setAudioElement(audioElement);
        callback(audioElement);
    };

    const handleUploadMusic = (e) => {
        console.info("Upload music", e.target.files);
        if (e.target.files.length > 0) {
            setAudioPath(null);
            if (audioElement) {
                audioElement.pause();
                audioElement.currentTime = 0;
            }
            let file = e.target.files[0];
            let audioBlob = new Blob([file], { type: 'audio/mp3' });

            setUploading(true);
            serviceHttpRequest('onerecord/monitor/upload', { 'method': "POST", 'body': audioBlob },
                uploadedEvent => {
                    setUploading(false);
                },
                uploadprogressEvent => {
                    setUploadProgress(uploadprogressEvent.loaded / uploadprogressEvent.total);
                }
            ).then(data => {
                setUploading(false);
                setAudioPath(URL.createObjectURL(file));
            }).catch(error => {
                console.debug(error);
            });
        }
    };

    return (
        <div className="LeftPanel">
            <h2>Configura la Grabación</h2>
            <div style={{ display: display }}>
                <span>Selecciona un micrófono para grabar</span>
                <ul>
                    {loadingDevices && <li>Loading devices...</li>}
                    {!loadingDevices && <ChoicesLiveUpdate options={devices} style={{ width: "100%" }} value={selectedDevice} onUpdate={(v) => setSelectedDevice(v)} />}
                </ul>

                <MicIcon size="24" /> <input type="range" min="0" max="100" value={micVolume * 100} className="AudioGoDemosComponent-volume-range" onChange={(e) => {
                    setMicVolume(e.target.value / 100);
                }} />
                <div>&nbsp;</div>
                <span>Graba en silencio o carga tu música</span>
                <div className="upload-music">
                    {uploading && <div>Subiendo música ({Math.floor(uploadProgress * 100)}%)...</div>}
                    {!uploading && <>
                        <label for="upload-music">Carga tu MP3</label>
                        <input id="upload-music" type="file" accept="audio/*" onChange={handleUploadMusic} style={{ display: 'none' }} />
                    </>}
                </div>

                {audioPath && <>
                    <div>&nbsp;</div>
                    <span>Escucha la música</span>
                    <AudioGoDemosComponent controls src={audioPath} callback={callbackAudioElement}></AudioGoDemosComponent>
                    <div>&nbsp;</div>
                    <span>Deseas Monitorear el Audio</span>
                    <CheckboxLiveUpdate value={monitor} object={{ id: "M", nombre: "Monitor" }} onUpdate={(v) => { setMonitor(v) }} />
                </>}
                <div>&nbsp;</div>
                <span>Ajustes de Audio</span>
                <CheckboxLiveUpdate value={echoCancelation} object={{ id: "E", nombre: "Cancelar Eco" }} onUpdate={(v) => { setEchoCancelation(v) }} />
                <CheckboxLiveUpdate value={noiseSuppression} object={{ id: "N", nombre: "Supresión de Ruido" }} onUpdate={(v) => { setNoiseSuppression(v) }} />
            </div>
        </div>
    );
};

const RightPanel = ({ audioBlob = null, setMain, setAudioPath, silence = true }) => {

    const [audioURL, setAudioURL] = useState(null);
    const [uploading, setUploading] = useState("");
    const [uploadprogress, setUploadProgress] = useState(0);
    const [mixing, setMixing] = useState(false);
    const [mixedAudio, setMixedAudio] = useState(null);
    const descripcionElement = React.createRef();

    const [url2mix, setUrl2Mix] = useState("onerecord/mix/silence");

    useEffect(() => {
        console.info("Silence", silence);
        if (silence) {
            setUrl2Mix("onerecord/mix/silence");
        } else {
            setUrl2Mix(`onerecord/mix`);
        }
    }, [silence]);

    const uploadAndMix = () => {
        setUploading(true);
        setMixing(false);
        const formData = new FormData();
        formData.append("audio", audioBlob);
        serviceHttpRequest(url2mix, { 'method': "POST", 'body': audioBlob },
            uploadedEvent => {
                setMixing(true);
                setUploading(false);
            },
            uploadprogressEvent => {
                setUploadProgress(uploadprogressEvent.loaded / uploadprogressEvent.total);
            })
            .then(data => {
                setMixedAudio(data.mix_url);
                setMixing(false);
            }).catch(error => {
                alert("Ha ocurrido un error al generar la mezcla. Intenta generar nuevamente la mezcla o escríbenos a soporte@godemos.cl.");
            });
    };

    const saveAudiolocutor = (e) => {
        let textoPrevio = e.target.textContent;
        e.target.textContent = "Guardando...";
        let form = new FormData();
        form.append("detalle", descripcionElement.current.value);
        serviceFetch(`onerecord/save`, "POST", form)
            .then(data => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Error al guardar el audio");
            })
            .then(json => {
                // console.debug("setAudiopath: ", json.audio_locutor.path_mezclado);
                setMain("justsaved");
                setAudioPath(json.audio_locutor.path_mezclado);
            })
            .catch(error => {
                console.debug(error);
                e.target.textContent = textoPrevio;
            });
    };

    useEffect(() => {
        console.debug("AudioBlob", audioBlob);
        if (audioBlob) {
            setAudioURL(URL.createObjectURL(audioBlob));
            setMixedAudio(null);
            setUploading(false);
        } else {
            setAudioURL(null);
            setMixedAudio(null);
            setUploading("");
        }
    }, [audioBlob]);

    return (
        <div className="RightPanel">
            <h2>Escucha tu grabación</h2>
            {audioURL && <AudioGoDemosComponent src={`${audioURL}`} />}
            <div>&nbsp;</div>
            {(url2mix && audioURL && !uploading && !mixing && !mixedAudio) && <button onClick={uploadAndMix}>Generar Mezcla</button>}
            {(uploading || false) && <>
                <div className="mixingIndicator">
                    <CloudArrowUp size="24" />
                    <span> Subiendo audio ({Math.floor(uploadprogress * 100)}%)...</span>
                </div>
            </>}
            {(mixing || false) && <>
                <div className="mixingIndicator">
                    <PCICardIcon size="24" />
                    <span> Generando mezcla...</span>
                </div>
            </>}
            {mixedAudio && <>
                <div>&nbsp;</div>
                <h2>Escucha tu mezcla</h2>
                <div className="EcuchaTuMezcla">
                    <AudioGoDemosComponent src={`${mixedAudio}?${Math.random()}`} />
                </div>
                <div><input ref={descripcionElement} placeholder="Descripción del audio" className="audio-descripcion" /></div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <button onClick={saveAudiolocutor}>Guarda tu Demo</button>
            </>}
        </div>
    );
};

const CenterPanel = ({ recording, startRecording, stopRecording, duration = 0 }) => {
    const [textSize, setTextSize] = useState(16);
    const textPanel = React.createRef();

    const timer = React.useRef(null);


    const handlePaste = (e) => {
        e.preventDefault();
        navigator.clipboard.readText().then(function (text) {
            textPanel.current.value = text; // Set the pasted text into the textarea
        }).catch(function (error) {
            console.error('Error accessing clipboard: ', error);
        });
    };

    return (
        <div className="CenterPanel">
            <div className="CenterPanel-textbuttons">
                <button onClick={() => setTextSize(textSize + 1)}>Aumentar tamaño</button>
                <button onClick={() => setTextSize(textSize - 1)}>Disminuir tamaño</button>
                <small onClick={handlePaste} style={{ cursor: 'pointer' }}><ClipboardIcon size="16" /> pegar</small>
            </div>
            <textarea ref={textPanel} style={{ fontSize: textSize }} placeholder="Aquí puedes pegar el texto que vas a interpretar."></textarea>
            {(!recording && duration > 0) &&
                <div className="GrabarSection">
                    <div className="GrabarTimer">
                        <StopwatchIcon size="24" />
                        <select ref={timer} onChange={e => localStorage.setItem("timer", e.target.value)}>
                            <option value={0} selected={localStorage.getItem("timer") === "0"}>0s</option>
                            <option value={5} selected={localStorage.getItem("timer") === "5"}>5s</option>
                            <option value={10} selected={localStorage.getItem("timer") === "10"}>10s</option>
                            <option value={15} selected={localStorage.getItem("timer") === "15"}>15s</option>
                        </select>
                    </div>
                    <button className="GrabarButton" onClick={() => startRecording(timer.current ? Math.floor(timer.current.value) : 0)}>Grabar</button>
                </div>}
            {recording && <>
                <button id="CentralPanel_stopRecording" onClick={() => stopRecording()}>Detener</button>
                <span>&nbsp;</span>
                <TimeCounter time={duration} />
            </>}
        </div>
    );
};

const TimeCounter = ({ time }) => {

    let totalminutes = parseInt(time / 60, 10);
    let totalseconds = parseInt(time % 60, 10);

    totalminutes = totalminutes < 10 ? "0" + totalminutes : totalminutes;
    totalseconds = totalseconds < 10 ? "0" + totalseconds : totalseconds;

    const [counter, setCounter] = useState(0);

    let minutes = parseInt(counter / 60, 10);
    let seconds = parseInt(counter % 60, 10);

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    React.useEffect(() => {
        let aux = 0;
        const interval = setInterval(() => {
            if (aux < time) {
                setCounter(counter => counter + 1);
                aux++;
            } else {
                clearInterval(interval);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [time]);




    return (
        <div className="CenterPanel-counter">
            <div>{minutes}:{seconds}</div>
            <div className="CenterPanel-counter-bar">
                <div style={{ width: `${(counter / time) * 100}%` }}></div>
            </div>
            <div>{totalminutes}:{totalseconds}</div>
        </div>
    );
};

export default AudiosViewRecordContainer;
import React, { useState } from "react";
import '../styles/views/GrabarView.css';
import Demoteca from "../containers/DemotecaContainer.jsx";
// import { AudioTrack, AudioTracks } from "../components/AudioTrack.jsx";
import { ShareIcon, ToggleOffIcon, ToggleOnIcon } from "../components/BootstrapIcons.jsx";
// import AudioElementComponent from "../components/AudioElementComponent.jsx";
import AudioGoDemosComponent from "../components/AudioGoDemosComponent.jsx";
import { useFetch } from "../hooks/useFetch.jsx";
import { backendRoot } from "../contexts/BackendProps.jsx";
// import { backendRoot } from "../contexts/BackendProps.jsx";

 
const DemotecaView = ({ setActivity }) => {

    const [main, setMain] = useState("demoteca");
    const [audioPath, setAudioPath] = useState(null);
    const [autoplay, setAutoplay] = useState(false);
    // eslint-disable-next-line
    const {loading, error, data} = useFetch("demoteca/candownload");

    const handleShare = (e) => {
        if (navigator.share && data?.success===1) {
            navigator.share({
                title: 'GoDemos',
                text: 'Mira mi perfil en GoDemos',
                url: `${backendRoot}/perfil/${data.username}`
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch(console.error);
        } else if(data?.success===1){
            window.open(`${backendRoot}/perfil/${data.username}`, '_blank');
        } else {
            alert("No se puede compartir el perfil en este momento");
        }
    };

    return (
        <div className="GrabarView">
            <div style={{ width: '100%', textAlign: 'center' }}>
                <h1>Graba tus demos</h1>
                <label className="SwitchInput">
                    <input type="checkbox" checked={autoplay} onChange={(e) => setAutoplay(e.target.checked)} style={{ display: 'none' }} />
                    {autoplay ? <ToggleOnIcon style={{color:'green'}} /> : <ToggleOffIcon style={{color:'black'}} />}&nbsp;Reproducir automáticamente
                </label>
                {data?.success && <label onClick={handleShare} style={{cursor: 'pointer',background: 'var(--green-6)',padding: '5px 10px',borderRadius: '10px',margin: '10px',display: 'inline-block',fontSize: '14px'}}><ShareIcon size="16" /> Compartir Perfil</label>}
                {main === "demoteca" && <Demoteca setMain={setMain} setAudioPath={setAudioPath} canRecord={true} />}
                {main === "justsaved" && <div className="JustRecorded">
                    <img src="https://godemos.cl/static/desktop/assets/img/logo.webp" alt="logo" height={128}/>
                    <p>¡Felicitaciones! tu demo ha quedado guardado con éxito</p>
                    <button onClick={e =>{
                        setMain("demoteca");
                        setAudioPath(null);
                    }}>Volver a la Demoteca</button>
                </div>}
            </div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            {audioPath &&
                // <AudioElementComponent autoplay={autoplay} audioTrack={`${audioPath}`} style={{width:'400px'}} />
                <AudioGoDemosComponent src={`${audioPath}`} autoplay={autoplay} style={{width:'400px',alignItems:'center'}} downloadable={data?.success===1} />
            }
            <div>&nbsp;</div>
            <div>&nbsp;</div>
        </div>
    );
};


export default DemotecaView;
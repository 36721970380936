//import logo from './logo.svg';
import './App.css';
import MenuTopContainer from './containers/MenuTopContainer.jsx';
import MenuLeftContainer from './containers/MenuLeftContainer.jsx';
import Activity from './containers/Activity.jsx';
import { useState } from 'react';

function App() {

  const [ activity, setActivity ] = useState(null);
  const [ isAuth, setIsAuth ] = useState(false);

  // const [path, setPath] = useState(window.location.pathname);

  return (
    <div className="GoDemos">
      <header>
        <MenuTopContainer />
      </header>
      <div>
        {isAuth && <MenuLeftContainer isAuth={isAuth} activity={activity} setActivity={setActivity} />}
        <Activity activity={activity} setActivity={setActivity} setIsAuth={setIsAuth} />
      </div>
    </div>
  );
}

export default App;

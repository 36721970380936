import React from "react";

import { serviceFetch } from "../utils/serviceFetch.js";
import { useState } from "react";

import "../styles/RecordFieldElement.css";

const RecordFieldElement = ({ name, value, allowEdit = true, url, options = null , style=null}) =>{
    
    const [editing, setEditing] = useState(false);
    const [copyValue, setCopyValue] = useState(value);
    const [realValue, setRealValue] = useState(value);

    const handleDoubleClick = (e) => {
        if (allowEdit && !editing) {
            setEditing(true);
        }
    };

    const handleOnBlur = (e) => {
        setEditing(false);
        let formData = new FormData();
        formData.append("field", name);
        formData.append("value", e.target.value);
        serviceFetch(url, "POST", formData)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setRealValue(data.value);
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                console.log("Fetch finalizado");
            });
    };

    return (
        <>
            {editing === true &&
                <span className="InputEdit" style={style}>
                    {options ?
                        <select autoFocus name={name} onBlur={handleOnBlur}>
                            {options.map((opt) => (
                                <option key={opt.value} value={opt.value} selected={opt.value === realValue}>{opt.label}</option>
                            ))}
                        </select>
                        : <input autoFocus type="text" name={name} onChange={(e) => setCopyValue(e.target.value)} onBlur={handleOnBlur} value={copyValue} />}
                </span>
            }
            <span className="RecordField" onDoubleClick={handleDoubleClick} style={style}>{
                options ?
                    options.find((item) => item.value === realValue)?.label
                    : realValue
            }</span>
        </>
    );
};

export default RecordFieldElement;